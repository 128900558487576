const addressList = {
  四川: {
    阿坝藏族羌族自治州: {
      阿坝县: "513231",
      黑水县: "513228",
      红原县: "513233",
      金川县: "513226",
      九寨沟县: "513225",
      理县: "513222",
      马尔康市: "513229",
      茂县: "513223",
      其它区: "513234",
      壤塘县: "513230",
      若尔盖县: "513232",
      松潘县: "513224",
      汶川县: "513221",
      小金县: "513227"
    },
    巴中市: {
      巴州区: "511902",
      恩阳区: "511903",
      南江县: "511922",
      平昌县: "511923",
      其它区: "511924",
      通江县: "511921",
      巴中经济开发区: "511971"
    },
    成都市: {
      成华区: "510108",
      崇州市: "510184",
      大邑县: "510129",
      都江堰市: "510181",
      简阳市: "512081",
      锦江区: "510104",
      金牛区: "510106",
      金堂县: "510121",
      龙泉驿区: "510112",
      彭州市: "510182",
      郫都区: "510124",
      蒲江县: "510131",
      青白江区: "510113",
      青羊区: "510105",
      邛崃市: "510183",
      其它区: "510185",
      双流区: "510122",
      温江区: "510115",
      武侯区: "510107",
      新都区: "510114",
      新津区: "510132"
    },
    达州市: {
      达川区: "511721",
      大竹县: "511724",
      开江县: "511723",
      其它区: "511782",
      渠县: "511725",
      通川区: "511702",
      万源市: "511781",
      宣汉县: "511722",
      达州经济开发区: "511771"
    },
    德阳市: {
      广汉市: "510681",
      旌阳区: "510603",
      罗江区: "510626",
      绵竹市: "510683",
      其它区: "510684",
      什邡市: "510682",
      中江县: "510623"
    },
    甘孜藏族自治州: {
      白玉县: "513331",
      巴塘县: "513335",
      丹巴县: "513323",
      稻城县: "513337",
      道孚县: "513326",
      德格县: "513330",
      得荣县: "513338",
      甘孜县: "513328",
      九龙县: "513324",
      康定市: "513321",
      理塘县: "513334",
      泸定县: "513322",
      炉霍县: "513327",
      其它区: "513339",
      色达县: "513333",
      石渠县: "513332",
      乡城县: "513336",
      新龙县: "513329",
      雅江县: "513325"
    },
    广安市: {
      广安区: "511602",
      华蓥市: "511681",
      邻水县: "511623",
      前锋区: "511603",
      其它区: "511683",
      武胜县: "511622",
      岳池县: "511621"
    },
    广元市: {
      苍溪县: "510824",
      朝天区: "510812",
      剑阁县: "510823",
      利州区: "510802",
      青川县: "510822",
      其它区: "510825",
      旺苍县: "510821",
      昭化区: "510811"
    },
    乐山市: {
      峨边彝族自治县: "511132",
      峨眉山市: "511181",
      夹江县: "511126",
      井研县: "511124",
      金口河区: "511113",
      马边彝族自治县: "511133",
      沐川县: "511129",
      犍为县: "511123",
      其它区: "511182",
      沙湾区: "511111",
      市中区: "511102",
      五通桥区: "511112"
    },
    凉山彝族自治州: {
      布拖县: "513429",
      德昌县: "513424",
      甘洛县: "513435",
      会东县: "513426",
      会理县: "513425",
      金阳县: "513430",
      雷波县: "513437",
      美姑县: "513436",
      冕宁县: "513433",
      木里藏族自治县: "513422",
      宁南县: "513427",
      普格县: "513428",
      其它区: "513438",
      西昌市: "513401",
      喜德县: "513432",
      盐源县: "513423",
      越西县: "513434",
      昭觉县: "513431"
    },
    泸州市: {
      古蔺县: "510525",
      合江县: "510522",
      江阳区: "510502",
      龙马潭区: "510504",
      泸县: "510521",
      纳溪区: "510503",
      其它区: "510526",
      叙永县: "510524"
    },
    眉山市: {
      丹棱县: "511424",
      东坡区: "511402",
      洪雅县: "511423",
      彭山区: "511422",
      青神县: "511425",
      其它区: "511426",
      仁寿县: "511421"
    },
    绵阳市: {
      安州区: "510724",
      北川羌族自治县: "510726",
      涪城区: "510703",
      江油市: "510781",
      平武县: "510727",
      其它区: "510782",
      三台县: "510722",
      盐亭县: "510723",
      游仙区: "510704",
      梓潼县: "510725"
    },
    南充市: {
      高坪区: "511303",
      嘉陵区: "511304",
      阆中市: "511381",
      南部县: "511321",
      蓬安县: "511323",
      其它区: "511382",
      顺庆区: "511302",
      西充县: "511325",
      仪陇县: "511324",
      营山县: "511322"
    },
    内江市: {
      东兴区: "511011",
      隆昌市: "511028",
      其它区: "511029",
      市中区: "511002",
      威远县: "511024",
      资中县: "511025",
      内江经济开发区: "511071"
    },
    攀枝花市: {
      东区: "510402",
      米易县: "510421",
      其它区: "510423",
      仁和区: "510411",
      西区: "510403",
      盐边县: "510422"
    },
    遂宁市: {
      安居区: "510904",
      船山区: "510903",
      大英县: "510923",
      蓬溪县: "510921",
      其它区: "510924",
      射洪县: "510922"
    },
    雅安市: {
      宝兴县: "511827",
      汉源县: "511823",
      芦山县: "511826",
      名山区: "511821",
      其它区: "511828",
      石棉县: "511824",
      天全县: "511825",
      荥经县: "511822",
      雨城区: "511802"
    },
    宜宾市: {
      长宁县: "511524",
      翠屏区: "511502",
      高县: "511525",
      珙县: "511526",
      江安县: "511523",
      筠连县: "511527",
      南溪区: "511522",
      屏山县: "511529",
      其它区: "511530",
      兴文县: "511528",
      叙州区: "511521"
    },
    自贡市: {
      大安区: "510304",
      富顺县: "510322",
      贡井区: "510303",
      其它区: "510323",
      荣县: "510321",
      沿滩区: "510311",
      自流井区: "510302"
    },
    资阳市: {
      安岳县: "512021",
      乐至县: "512022",
      其它区: "512082",
      雁江区: "512002"
    }
  },
  新疆维吾尔: {
    阿克苏地区: {
      阿克苏市: "652901",
      阿瓦提县: "652928",
      拜城县: "652926",
      柯坪县: "652929",
      库车县: "652923",
      其它区: "652930",
      沙雅县: "652924",
      温宿县: "652922",
      乌什县: "652927",
      新和县: "652925"
    },
    阿拉尔市: "659002",
    阿勒泰地区: {
      阿勒泰市: "654301",
      布尔津县: "654321",
      福海县: "654323",
      富蕴县: "654322",
      哈巴河县: "654324",
      吉木乃县: "654326",
      青河县: "654325",
      其它区: "654327"
    },
    巴音郭楞蒙古自治州: {
      博湖县: "652829",
      和静县: "652827",
      和硕县: "652828",
      库尔勒市: "652801",
      轮台县: "652822",
      且末县: "652825",
      其它区: "652830",
      若羌县: "652824",
      焉耆回族自治县: "652826",
      尉犁县: "652823"
    },
    北屯市: "659005",
    博尔塔拉蒙古自治州: {
      阿拉山口市: "652702",
      博乐市: "652701",
      精河县: "652722",
      其它区: "652724",
      温泉县: "652723"
    },
    昌吉回族自治州: {
      昌吉市: "652301",
      阜康市: "652302",
      呼图壁县: "652323",
      吉木萨尔县: "652327",
      玛纳斯县: "652324",
      木垒哈萨克自治县: "652328",
      奇台县: "652325",
      其它区: "652329"
    },
    哈密市: {
      巴里坤哈萨克自治县: "652222",
      其它区: "652224",
      伊吾县: "652223",
      伊州区: "652201"
    },
    和田地区: {
      策勒县: "653225",
      和田市: "653201",
      和田县: "653221",
      洛浦县: "653224",
      民丰县: "653227",
      墨玉县: "653222",
      皮山县: "653223",
      其它区: "653228",
      于田县: "653226"
    },
    喀什地区: {
      巴楚县: "653130",
      伽师县: "653129",
      喀什市: "653101",
      麦盖提县: "653127",
      其它区: "653132",
      莎车县: "653125",
      疏附县: "653121",
      疏勒县: "653122",
      塔什库尔干塔吉克自治县: "653131",
      叶城县: "653126",
      英吉沙县: "653123",
      岳普湖县: "653128",
      泽普县: "653124"
    },
    可克达拉市: { 可克达拉市: "659008" },
    克拉玛依市: {
      白碱滩区: "650204",
      独山子区: "650202",
      克拉玛依区: "650203",
      其它区: "650206",
      乌尔禾区: "650205"
    },
    克孜勒苏柯尔克孜自治州: {
      阿合奇县: "653023",
      阿克陶县: "653022",
      阿图什市: "653001",
      其它区: "653025",
      乌恰县: "653024"
    },
    昆玉市: "659009",
    石河子市: "659001",
    双河市: "659007",
    胡杨河市: "659010",
    塔城地区: {
      额敏县: "654221",
      和布克赛尔蒙古自治县: "654226",
      其它区: "654227",
      沙湾县: "654223",
      塔城市: "654201",
      托里县: "654224",
      乌苏市: "654202",
      裕民县: "654225"
    },
    铁门关市: "659006",
    吐鲁番市: {
      高昌区: "652101",
      其它区: "652124",
      鄯善县: "652122",
      托克逊县: "652123"
    },
    图木舒克市: "659003",
    五家渠市: "659004",
    乌鲁木齐市: {
      达坂城区: "650107",
      米东区: "650109",
      其它区: "650122",
      沙依巴克区: "650103",
      水磨沟区: "650105",
      天山区: "650102",
      头屯河区: "650106",
      乌鲁木齐县: "650121",
      新市区: "650104"
    },
    伊犁哈萨克自治州: {
      察布查尔锡伯自治县: "654022",
      巩留县: "654024",
      霍城县: "654023",
      霍尔果斯市: "654004",
      奎屯市: "654003",
      尼勒克县: "654028",
      其它区: "654029",
      特克斯县: "654027",
      新源县: "654025",
      伊宁市: "654002",
      伊宁县: "654021",
      昭苏县: "654026"
    }
  },
  内蒙古: {
    阿拉善盟: {
      阿拉善右旗: "152922",
      阿拉善左旗: "152921",
      额济纳旗: "152923",
      其它区: "152924",
      内蒙古阿拉善经济开发区: "	152971"
    },
    包头市: {
      白云鄂博矿区: "150206",
      达尔罕茂明安联合旗: "150223",
      东河区: "150202",
      固阳县: "150222",
      九原区: "150207",
      昆都仑区: "150203",
      青山区: "150204",
      其它区: "150224",
      石拐区: "150205",
      土默特右旗: "150221",
      包头稀土高新技术产业开发区: "	1502710"
    },
    巴彦淖尔市: {
      磴口县: "150822",
      杭锦后旗: "150826",
      临河区: "150802",
      其它区: "150827",
      乌拉特后旗: "150825",
      乌拉特前旗: "150823",
      乌拉特中旗: "150824",
      五原县: "150821"
    },
    赤峰市: {
      阿鲁科尔沁旗: "150421",
      敖汉旗: "150430",
      巴林右旗: "150423",
      巴林左旗: "150422",
      红山区: "150402",
      喀喇沁旗: "150428",
      克什克腾旗: "150425",
      林西县: "150424",
      宁城县: "150429",
      其它区: "150431",
      松山区: "150404",
      翁牛特旗: "150426",
      元宝山区: "150403"
    },
    鄂尔多斯市: {
      达拉特旗: "150621",
      东胜区: "150602",
      鄂托克旗: "150624",
      鄂托克前旗: "150623",
      杭锦旗: "150625",
      康巴什区: "150603",
      其它区: "150628",
      乌审旗: "150626",
      伊金霍洛旗: "150627",
      准格尔旗: "150622"
    },
    呼和浩特市: {
      和林格尔县: "150123",
      回民区: "150103",
      清水河县: "150124",
      其它区: "150126",
      赛罕区: "150105",
      土默特左旗: "150121",
      托克托县: "150122",
      武川县: "150125",
      新城区: "150102",
      玉泉区: "150104",
      呼和浩特经济技术开发区: "150172"
    },
    呼伦贝尔市: {
      阿荣旗: "150721",
      陈巴尔虎旗: "150725",
      额尔古纳市: "150784",
      鄂伦春自治旗: "150723",
      鄂温克族自治旗: "150724",
      根河市: "150785",
      海拉尔区: "150702",
      满洲里市: "150781",
      莫力达瓦达斡尔族自治旗: "150722",
      其它区: "150786",
      新巴尔虎右旗: "150727",
      新巴尔虎左旗: "150726",
      牙克石市: "150782",
      扎赉诺尔区: "150703",
      扎兰屯市: "150783"
    },
    通辽市: {
      霍林郭勒市: "150581",
      开鲁县: "150523",
      科尔沁区: "150502",
      科尔沁左翼后旗: "150522",
      科尔沁左翼中旗: "150521",
      库伦旗: "150524",
      奈曼旗: "150525",
      其它区: "150582",
      扎鲁特旗: "150526",
      通辽经济技术开发区: "150571"
    },
    乌海市: {
      海勃湾区: "150302",
      海南区: "150303",
      其它区: "150305",
      乌达区: "150304"
    },
    乌兰察布市: {
      察哈尔右翼后旗: "150928",
      察哈尔右翼前旗: "150926",
      察哈尔右翼中旗: "150927",
      丰镇市: "150981",
      化德县: "150922",
      集宁区: "150902",
      凉城县: "150925",
      其它区: "150982",
      商都县: "150923",
      四子王旗: "150929",
      兴和县: "150924",
      卓资县: "150921"
    },
    锡林郭勒盟: {
      阿巴嘎旗: "152522",
      东乌珠穆沁旗: "152525",
      多伦县: "152531",
      二连浩特市: "152501",
      其它区: "152532",
      苏尼特右旗: "152524",
      苏尼特左旗: "152523",
      太仆寺旗: "152527",
      镶黄旗: "152528",
      锡林浩特市: "152502",
      西乌珠穆沁旗: "152526",
      正蓝旗: "152530",
      正镶白旗: "152529",
      乌拉盖管委会: "152571"
    },
    兴安盟: {
      阿尔山市: "152202",
      科尔沁右翼前旗: "152221",
      科尔沁右翼中旗: "152222",
      其它区: "152225",
      突泉县: "152224",
      乌兰浩特市: "152201",
      扎赉特旗: "152223"
    }
  },
  西藏: {
    阿里地区: {
      措勤县: "542527",
      噶尔县: "542523",
      改则县: "542526",
      革吉县: "542525",
      普兰县: "542521",
      其它区: "542528",
      日土县: "542524",
      札达县: "542522"
    },
    昌都市: {
      八宿县: "542127",
      边坝县: "542133",
      察雅县: "542126",
      丁青县: "542125",
      贡觉县: "542123",
      江达县: "542122",
      卡若区: "542121",
      类乌齐县: "542124",
      洛隆县: "542132",
      芒康县: "542129",
      其它区: "542134",
      左贡县: "542128"
    },
    拉萨市: {
      城关区: "540102",
      当雄县: "540122",
      达孜区: "540126",
      堆龙德庆区: "540125",
      林周县: "540121",
      墨竹工卡县: "540127",
      尼木县: "540123",
      其它区: "540128",
      曲水县: "540124",
      格尔木藏青工业园区: "540171",
      拉萨经济技术开发区: "540172",
      西藏文化旅游创意园区: "540173",
      达孜工业园区: "540174"
    },
    林芝市: {
      巴宜区: "542621",
      波密县: "542625",
      察隅县: "542626",
      工布江达县: "542622",
      朗县: "542627",
      米林县: "542623",
      墨脱县: "542624",
      其它区: "542628"
    },
    那曲市: {
      安多县: "542425",
      班戈县: "542428",
      巴青县: "542429",
      比如县: "542423",
      嘉黎县: "542422",
      聂荣县: "542424",
      尼玛县: "542430",
      其它区: "542431",
      色尼区: "542421",
      申扎县: "542426",
      双湖县: "542432",
      索县: "542427"
    },
    日喀则市: {
      昂仁县: "542327",
      白朗县: "542329",
      定结县: "542332",
      定日县: "542324",
      岗巴县: "542338",
      江孜县: "542323",
      吉隆县: "542335",
      康马县: "542331",
      拉孜县: "542326",
      南木林县: "542322",
      聂拉木县: "542336",
      其它区: "542339",
      仁布县: "542330",
      萨嘎县: "542337",
      萨迦县: "542325",
      桑珠孜区: "542301",
      谢通门县: "542328",
      亚东县: "542334",
      仲巴县: "542333"
    },
    山南市: {
      措美县: "542227",
      错那县: "542232",
      贡嘎县: "542223",
      加查县: "542229",
      浪卡子县: "542233",
      隆子县: "542231",
      洛扎县: "542228",
      乃东区: "542221",
      琼结县: "542225",
      其它区: "542234",
      曲松县: "542226",
      桑日县: "542224",
      扎囊县: "542222"
    }
  },
  陕西: {
    安康市: {
      白河县: "610929",
      汉滨区: "610902",
      汉阴县: "610921",
      岚皋县: "610925",
      宁陕县: "610923",
      平利县: "610926",
      其它区: "610930",
      石泉县: "610922",
      旬阳县: "610928",
      镇坪县: "610927",
      紫阳县: "610924"
    },
    宝鸡市: {
      陈仓区: "610304",
      凤翔县: "610322",
      扶风县: "610324",
      凤县: "610330",
      金台区: "610303",
      麟游县: "610329",
      陇县: "610327",
      眉县: "610326",
      千阳县: "610328",
      岐山县: "610323",
      其它区: "610332",
      太白县: "610331",
      渭滨区: "610302"
    },
    汉中市: {
      城固县: "610722",
      佛坪县: "610730",
      汉台区: "610702",
      留坝县: "610729",
      略阳县: "610727",
      勉县: "610725",
      南郑区: "610721",
      宁强县: "610726",
      其它区: "610731",
      西乡县: "610724",
      洋县: "610723",
      镇巴县: "610728"
    },
    商洛市: {
      丹凤县: "611022",
      洛南县: "611021",
      其它区: "611027",
      商南县: "611023",
      商州区: "611002",
      山阳县: "611024",
      柞水县: "611026",
      镇安县: "611025"
    },
    铜川市: {
      其它区: "610223",
      王益区: "610202",
      耀州区: "610204",
      宜君县: "610222",
      印台区: "610203"
    },
    渭南市: {
      白水县: "610527",
      澄城县: "610525",
      大荔县: "610523",
      富平县: "610528",
      韩城市: "610581",
      合阳县: "610524",
      华阴市: "610582",
      华州区: "610521",
      临渭区: "610502",
      蒲城县: "610526",
      其它区: "610583",
      潼关县: "610522"
    },
    西安市: {
      灞桥区: "610111",
      碑林区: "610103",
      长安区: "610116",
      高陵区: "610126",
      鄠邑区: "610125",
      蓝田县: "610122",
      莲湖区: "610104",
      临潼区: "610115",
      其它区: "610127",
      未央区: "610112",
      新城区: "610102",
      阎良区: "610114",
      雁塔区: "610113",
      周至县: "610124"
    },
    咸阳市: {
      彬州市: "610427",
      长武县: "610428",
      淳化县: "610430",
      泾阳县: "610423",
      礼泉县: "610425",
      乾县: "610424",
      秦都区: "610402",
      其它区: "610482",
      三原县: "610422",
      渭城区: "610404",
      武功县: "610431",
      兴平市: "610481",
      旬邑县: "610429",
      杨陵区: "610403",
      永寿县: "610426"
    },
    延安市: {
      安塞区: "610624",
      宝塔区: "610602",
      富县: "610628",
      甘泉县: "610627",
      黄陵县: "610632",
      黄龙县: "610631",
      洛川县: "610629",
      其它区: "610633",
      吴起县: "610626",
      延长县: "610621",
      延川县: "610622",
      宜川县: "610630",
      志丹县: "610625",
      子长县: "610623"
    },
    榆林市: {
      定边县: "610825",
      府谷县: "610822",
      横山区: "610823",
      佳县: "610828",
      靖边县: "610824",
      米脂县: "610827",
      清涧县: "610830",
      其它区: "610832",
      神木市: "610821",
      绥德县: "610826",
      吴堡县: "610829",
      榆阳区: "610802",
      子洲县: "610831"
    }
  },
  安徽: {
    安庆市: {
      大观区: "340803",
      怀宁县: "340822",
      潜山市: "340824",
      其它区: "340882",
      宿松县: "340826",
      太湖县: "340825",
      桐城市: "340881",
      望江县: "340827",
      迎江区: "340802",
      宜秀区: "340811",
      岳西县: "340828",
      安徽安庆经济开发区: "340882"
    },
    蚌埠市: {
      蚌山区: "340303",
      固镇县: "340323",
      淮上区: "340311",
      怀远县: "340321",
      龙子湖区: "340302",
      其它区: "340324",
      五河县: "340322",
      禹会区: "340304",
      蚌埠市高新技术开发区: "340371",
      蚌埠市经济开发区: "340372"
    },
    亳州市: {
      涡阳县: "341621",
      利辛县: "341623",
      蒙城县: "341622",
      谯城区: "341602",
      其它区: "341624"
    },
    池州市: {
      东至县: "341721",
      贵池区: "341702",
      青阳县: "341723",
      其它区: "341724",
      石台县: "341722"
    },
    滁州市: {
      定远县: "341125",
      凤阳县: "341126",
      来安县: "341122",
      琅琊区: "341102",
      明光市: "341182",
      南谯区: "341103",
      其它区: "341183",
      全椒县: "341124",
      天长市: "341181",
      苏滁现代产业园: "341171",
      滁州经济技术开发区: "341172"
    },
    阜阳市: {
      阜南县: "341225",
      界首市: "341282",
      临泉县: "341221",
      其它区: "341283",
      太和县: "341222",
      颍东区: "341203",
      颍泉区: "341204",
      颍上县: "341226",
      颍州区: "341202",
      阜阳合肥现代产业园区: "341271",
      阜阳经济技术开发区: "341272"
    },
    合肥市: {
      包河区: "340111",
      长丰县: "340121",
      巢湖市: "341400",
      肥东县: "340122",
      肥西县: "340123",
      庐江县: "341421",
      庐阳区: "340103",
      其它区: "340192",
      蜀山区: "340104",
      瑶海区: "340102",
      合肥高新技术产业开发区: "340171",
      合肥经济技术开发区: "340172",
      合肥新站高新技术产业开发区: "340173"
    },
    毫州市: {
      谯城区: "	341602",
      涡阳县: "	341621",
      蒙城县: "	341622",
      利辛县: "	341623"
    },
    淮北市: {
      杜集区: "340602",
      烈山区: "340604",
      其它区: "340622",
      濉溪县: "340621",
      相山区: "340603"
    },
    淮南市: {
      八公山区: "340405",
      大通区: "340402",
      凤台县: "340421",
      潘集区: "340406",
      其它区: "340422",
      寿县: "340499",
      田家庵区: "340403",
      谢家集区: "340404"
    },
    黄山市: {
      黄山区: "341003",
      徽州区: "341004",
      祁门县: "341024",
      其它区: "341025",
      歙县: "341021",
      屯溪区: "341002",
      休宁县: "341022",
      黟县: "341023"
    },
    六安市: {
      霍邱县: "341522",
      霍山县: "341525",
      金安区: "341502",
      金寨县: "341524",
      其它区: "341526",
      舒城县: "341523",
      叶集区: "341504",
      裕安区: "341503"
    },
    马鞍山市: {
      博望区: "340506",
      当涂县: "340521",
      含山县: "341423",
      和县: "341424",
      花山区: "340503",
      其它区: "340522",
      雨山区: "340504"
    },
    宿州市: {
      砀山县: "341321",
      灵璧县: "341323",
      其它区: "341325",
      泗县: "341324",
      萧县: "341322",
      埇桥区: "341302",
      宿州马鞍山现代产业园区: "341371",
      宿州经济技术开发区: "341372"
    },
    铜陵市: {
      郊区: "340711",
      其它区: "340722",
      铜官区: "340705",
      义安区: "340721",
      枞阳县: "340799"
    },
    芜湖市: {
      繁昌县: "340222",
      镜湖区: "340202",
      鸠江区: "340207",
      南陵县: "340223",
      其它区: "340224",
      三山区: "340208",
      芜湖县: "340221",
      无为县: "341422",
      弋江区: "340203",
      芜湖经济技术开发区: "340271",
      安徽芜湖长江大桥经济开发区: "340272"
    },
    宣城市: {
      广德县: "341822",
      旌德县: "341825",
      泾县: "341823",
      绩溪县: "341824",
      郎溪县: "341821",
      宁国市: "341881",
      其它区: "341882",
      宣州区: "341802",
      宣城市经济开发区: "341871"
    }
  },
  辽宁: {
    鞍山市: {
      海城市: "210381",
      立山区: "210304",
      千山区: "210311",
      其它区: "210382",
      台安县: "210321",
      铁东区: "210302",
      铁西区: "210303",
      岫岩满族自治县: "210323"
    },
    本溪市: {
      本溪满族自治县: "210521",
      桓仁满族自治县: "210522",
      明山区: "210504",
      南芬区: "210505",
      平山区: "210502",
      其它区: "210523",
      溪湖区: "210503"
    },
    朝阳市: {
      北票市: "211381",
      朝阳县: "211321",
      建平县: "211322",
      喀喇沁左翼蒙古族自治县: "211324",
      凌源市: "211382",
      龙城区: "211303",
      其它区: "211383",
      双塔区: "211302"
    },
    大连市: {
      长海县: "210224",
      甘井子区: "210211",
      金州区: "210213",
      旅顺口区: "210212",
      普兰店区: "210282",
      其它区: "210298",
      沙河口区: "210204",
      瓦房店市: "210281",
      西岗区: "210203",
      中山区: "210202",
      庄河市: "210283"
    },
    丹东市: {
      东港市: "210681",
      凤城市: "210682",
      宽甸满族自治县: "210624",
      其它区: "210683",
      元宝区: "210602",
      振安区: "210604",
      振兴区: "210603"
    },
    抚顺市: {
      东洲区: "210403",
      抚顺县: "210421",
      清原满族自治县: "210423",
      其它区: "210424",
      顺城区: "210411",
      望花区: "210404",
      新宾满族自治县: "210422",
      新抚区: "210402"
    },
    阜新市: {
      阜新蒙古族自治县: "210921",
      海州区: "210902",
      清河门区: "210905",
      其它区: "210923",
      太平区: "210904",
      细河区: "210911",
      新邱区: "210903",
      彰武县: "210922"
    },
    葫芦岛市: {
      建昌县: "211422",
      连山区: "211402",
      龙港区: "211403",
      南票区: "211404",
      其它区: "211482",
      绥中县: "211421",
      兴城市: "211481"
    },
    锦州市: {
      北镇市: "210782",
      古塔区: "210702",
      黑山县: "210726",
      凌海市: "210781",
      凌河区: "210703",
      其它区: "210783",
      太和区: "210711",
      义县: "210727"
    },
    辽阳市: {
      白塔区: "211002",
      灯塔市: "211081",
      弓长岭区: "211005",
      宏伟区: "211004",
      辽阳县: "211021",
      其它区: "211082",
      太子河区: "211011",
      文圣区: "211003"
    },
    盘锦市: {
      大洼区: "211121",
      盘山县: "211122",
      其它区: "211123",
      双台子区: "211102",
      兴隆台区: "211103"
    },
    沈阳市: {
      大东区: "210104",
      法库县: "210124",
      和平区: "210102",
      皇姑区: "210105",
      浑南区: "210112",
      康平县: "210123",
      辽中区: "210122",
      其它区: "210185",
      沈北新区: "210184",
      沈河区: "210103",
      苏家屯区: "210111",
      铁西区: "210106",
      新民市: "210181",
      于洪区: "210114"
    },
    铁岭市: {
      昌图县: "211224",
      调兵山市: "211281",
      开原市: "211282",
      清河区: "211204",
      其它区: "211283",
      铁岭县: "211221",
      西丰县: "211223",
      银州区: "211202"
    },
    营口市: {
      鲅鱼圈区: "210804",
      大石桥市: "210882",
      盖州市: "210881",
      老边区: "210811",
      其它区: "210883",
      西市区: "210803",
      站前区: "210802"
    }
  },
  贵州: {
    安顺市: {
      关岭布依族苗族自治县: "520424",
      平坝区: "520421",
      普定县: "520422",
      其它区: "520426",
      西秀区: "520402",
      镇宁布依族苗族自治县: "520423",
      紫云苗族布依族自治县: "520425"
    },
    毕节市: {
      大方县: "522422",
      赫章县: "522428",
      金沙县: "522424",
      纳雍县: "522426",
      黔西县: "522423",
      其它区: "522429",
      七星关区: "522401",
      威宁彝族回族苗族自治县: "522427",
      织金县: "522425"
    },
    贵阳市: {
      白云区: "520113",
      观山湖区: "520151",
      花溪区: "520111",
      开阳县: "520121",
      南明区: "520102",
      清镇市: "520181",
      其它区: "520182",
      乌当区: "520112",
      息烽县: "520122",
      修文县: "520123",
      云岩区: "520103"
    },
    六盘水市: {
      六枝特区: "520203",
      盘州市: "520222",
      其它区: "520223",
      水城县: "520221",
      钟山区: "520201"
    },
    黔东南苗族侗族自治州: {
      岑巩县: "522626",
      从江县: "522633",
      丹寨县: "522636",
      黄平县: "522622",
      剑河县: "522629",
      锦屏县: "522628",
      凯里市: "522601",
      雷山县: "522634",
      黎平县: "522631",
      麻江县: "522635",
      其它区: "522637",
      榕江县: "522632",
      三穗县: "522624",
      施秉县: "522623",
      台江县: "522630",
      天柱县: "522627",
      镇远县: "522625"
    },
    黔南布依族苗族自治州: {
      长顺县: "522729",
      独山县: "522726",
      都匀市: "522701",
      福泉市: "522702",
      贵定县: "522723",
      惠水县: "522731",
      荔波县: "522722",
      龙里县: "522730",
      罗甸县: "522728",
      平塘县: "522727",
      其它区: "522733",
      三都水族自治县: "522732",
      瓮安县: "522725"
    },
    黔西南布依族苗族自治州: {
      安龙县: "522328",
      册亨县: "522327",
      普安县: "522323",
      晴隆县: "522324",
      其它区: "522329",
      望谟县: "522326",
      兴仁县: "522322",
      兴义市: "522301",
      贞丰县: "522325"
    },
    铜仁市: {
      碧江区: "522201",
      德江县: "522227",
      江口县: "522222",
      其它区: "522231",
      石阡县: "522224",
      思南县: "522225",
      松桃苗族自治县: "522229",
      万山区: "522230",
      沿河土家族自治县: "522228",
      印江土家族苗族自治县: "522226",
      玉屏侗族自治县: "522223"
    },
    遵义市: {
      播州区: "520321",
      赤水市: "520381",
      道真仡佬族苗族自治县: "520325",
      凤冈县: "520327",
      红花岗区: "520302",
      汇川区: "520303",
      湄潭县: "520328",
      其它区: "520383",
      仁怀市: "520382",
      绥阳县: "520323",
      桐梓县: "520322",
      务川仡佬族苗族自治县: "520326",
      习水县: "520330",
      余庆县: "520329",
      正安县: "520324"
    }
  },
  河南: {
    安阳市: {
      安阳县: "410522",
      北关区: "410503",
      滑县: "410526",
      林州市: "410581",
      龙安区: "410506",
      内黄县: "410527",
      其它区: "410582",
      汤阴县: "410523",
      文峰区: "410502",
      殷都区: "410505",
      安阳高新技术产业开发区: "410571"
    },
    鹤壁市: {
      鹤山区: "410602",
      淇滨区: "410611",
      其它区: "410623",
      淇县: "410622",
      山城区: "410603",
      浚县: "410621",
      鹤壁经济技术开发区: "410671"
    },
    焦作市: {
      博爱县: "410822",
      解放区: "410802",
      马村区: "410804",
      孟州市: "410883",
      沁阳市: "410882",
      其它区: "410884",
      山阳区: "410811",
      温县: "410825",
      武陟县: "410823",
      修武县: "410821",
      中站区: "410803",
      焦作城乡一体化示范区: "410871"
    },
    济源市: {
      沁园街道: "419001001000",
      济水街道: "419001002000",
      北海街道: "419001003000",
      天坛街道: "	419001004000",
      玉泉街道: "	419001005000",
      克井镇: "	419001100000",
      五龙口镇: "	419001101000",
      轵城镇: "	419001102000",
      承留镇: "	419001103000",
      邵原镇: "	419001104000",
      坡头镇: "	419001105000",
      梨林镇: "	419001106000",
      大峪镇: "	419001107000",
      思礼镇: "	419001108000",
      王屋镇: "	419001109000",
      下冶镇: "	419001110000"
    },
    开封市: {
      鼓楼区: "410204",
      兰考县: "410225",
      龙亭区: "410202",
      其它区: "410226",
      杞县: "410221",
      顺河回族区: "410203",
      通许县: "410222",
      尉氏县: "410223",
      祥符区: "410224",
      禹王台区: "410205"
    },
    漯河市: {
      临颍县: "411122",
      其它区: "411123",
      召陵区: "411104",
      舞阳县: "411121",
      郾城区: "411103",
      源汇区: "411102",
      漯河经济技术开发区: "411171"
    },
    洛阳市: {
      瀍河回族区: "410304",
      涧西区: "410305",
      吉利区: "410306",
      老城区: "410302",
      栾川县: "410324",
      洛龙区: "410307",
      洛宁县: "410328",
      孟津县: "410322",
      其它区: "471005",
      汝阳县: "410326",
      嵩县: "410325",
      西工区: "410303",
      新安县: "410323",
      偃师市: "410381",
      伊川县: "410329",
      宜阳县: "410327",
      洛阳高新技术产业开发区: "410371"
    },
    南阳市: {
      邓州市: "411381",
      方城县: "411322",
      南召县: "411321",
      内乡县: "411325",
      其它区: "411382",
      社旗县: "411327",
      唐河县: "411328",
      桐柏县: "411330",
      宛城区: "411302",
      卧龙区: "411303",
      淅川县: "411326",
      新野县: "411329",
      西峡县: "411323",
      镇平县: "411324",
      南阳高新技术产业开发区: "411371",
      南阳市城乡一体化示范区: "411372"
    },
    平顶山市: {
      宝丰县: "410421",
      郏县: "410425",
      鲁山县: "410423",
      其它区: "410483",
      汝州市: "410482",
      石龙区: "410404",
      卫东区: "410403",
      舞钢市: "410481",
      新华区: "410402",
      叶县: "410422",
      湛河区: "410411",
      平顶山高新技术产业开发区: "410471",
      平顶山市城乡一体化示范区: "410472"
    },
    濮阳市: {
      范县: "410926",
      华龙区: "410902",
      南乐县: "410923",
      濮阳县: "410928",
      清丰县: "410922",
      其它区: "410929",
      台前县: "410927",
      濮阳工业园区: "410971",
      濮阳经济技术开发区: "410972"
    },
    三门峡市: {
      湖滨区: "411202",
      灵宝市: "411282",
      卢氏县: "411224",
      渑池县: "411221",
      其它区: "411283",
      陕州区: "411222",
      义马市: "411281",
      三门峡经济开发区: "411271"
    },
    商丘市: {
      梁园区: "411402",
      民权县: "411421",
      宁陵县: "411423",
      其它区: "411482",
      睢县: "411422",
      睢阳区: "411403",
      夏邑县: "411426",
      永城市: "411481",
      虞城县: "411425",
      柘城县: "411424",
      豫东综合快递产业聚集区: "411471",
      商丘经济开发区: "411472"
    },
    新乡市: {
      长垣县: "410728",
      封丘县: "410727",
      凤泉区: "410704",
      红旗区: "410702",
      辉县市: "410782",
      获嘉县: "410724",
      牧野区: "410711",
      其它区: "410783",
      卫滨区: "410703",
      卫辉市: "410781",
      新乡县: "410721",
      延津县: "410726",
      原阳县: "410725",
      新乡高新技术产业开发区: "410771",
      新乡经济技术开发区: "410772",
      新乡市平原城乡一体化示范区: "410773"
    },
    信阳市: {
      光山县: "411522",
      固始县: "411525",
      淮滨县: "411527",
      潢川县: "411526",
      罗山县: "411521",
      平桥区: "411503",
      其它区: "411529",
      商城县: "411524",
      浉河区: "411502",
      新县: "411523",
      息县: "411528",
      信阳高新技术产业开发区: "411571"
    },
    许昌市: {
      长葛市: "411082",
      建安区: "411023",
      其它区: "411083",
      魏都区: "411002",
      襄城县: "411025",
      鄢陵县: "411024",
      禹州市: "411081",
      许昌经济技术开发区: "411071"
    },
    郑州市: {
      登封市: "410185",
      二七区: "410103",
      巩义市: "410181",
      管城回族区: "410104",
      惠济区: "410108",
      金水区: "410105",
      其它区: "410188",
      上街区: "410106",
      荥阳市: "410182",
      新密市: "410183",
      新郑市: "410184",
      中牟县: "410122",
      中原区: "410102",
      郑州经济技术开发区: "410171",
      郑州高新技术产业开发区: "410172",
      郑州航空港经济综合实验区: "410173"
    },
    周口市: {
      川汇区: "411602",
      郸城县: "411625",
      扶沟县: "411621",
      淮阳县: "411626",
      鹿邑县: "411628",
      其它区: "411682",
      商水县: "411623",
      沈丘县: "411624",
      太康县: "411627",
      项城市: "411681",
      西华县: "411622",
      周口经济开发区: "411671"
    },
    驻马店市: {
      泌阳县: "411726",
      平舆县: "411723",
      其它区: "411730",
      确山县: "411725",
      汝南县: "411727",
      上蔡县: "411722",
      遂平县: "411728",
      新蔡县: "411729",
      西平县: "411721",
      驿城区: "411702",
      正阳县: "411724",
      驻马店经济开发区: "411771"
    }
  },
  吉林: {
    白城市: {
      大安市: "220882",
      其它区: "220883",
      洮北区: "220802",
      洮南市: "220881",
      通榆县: "220822",
      镇赉县: "220821",
      吉林白城经济开发区: "220871"
    },
    白山市: {
      长白朝鲜族自治县: "220623",
      抚松县: "220621",
      浑江区: "220602",
      江源区: "220625",
      靖宇县: "220622",
      临江市: "220681",
      其它区: "220682"
    },
    长春市: {
      朝阳区: "220104",
      德惠市: "220183",
      二道区: "220105",
      九台区: "220181",
      宽城区: "220103",
      绿园区: "220106",
      南关区: "220102",
      农安县: "220122",
      其它区: "220188",
      双阳区: "220112",
      榆树市: "220182",
      公主岭市: "220184",
      长春经济技术开发区: "220171",
      长春净月高新技术产业开发区: "220172",
      长春高新技术产业开发区: "220173",
      长春汽车经济技术开发区: "220174"
    },
    吉林市: {
      昌邑区: "220202",
      船营区: "220204",
      丰满区: "220211",
      桦甸市: "220282",
      蛟河市: "220281",
      龙潭区: "220203",
      磐石市: "220284",
      其它区: "220285",
      舒兰市: "220283",
      永吉县: "220221",
      吉林经济开发区: "220271",
      吉林高新技术产业开发区: "220272",
      吉林中国新加坡食品区: "220273"
    },
    辽源市: {
      东丰县: "220421",
      东辽县: "220422",
      龙山区: "220402",
      其它区: "220423",
      西安区: "220403"
    },
    四平市: {
      公主岭市: "220381",
      梨树县: "220322",
      其它区: "220383",
      双辽市: "220382",
      铁东区: "220303",
      铁西区: "220302",
      伊通满族自治县: "220323"
    },
    松原市: {
      长岭县: "220722",
      扶余市: "220724",
      宁江区: "220702",
      乾安县: "220723",
      前郭尔罗斯蒙古族自治县: "220721",
      其它区: "220725",
      吉林松原经济开发区: "220771"
    },
    通化市: {
      东昌区: "220502",
      二道江区: "220503",
      辉南县: "220523",
      集安市: "220582",
      柳河县: "220524",
      梅河口市: "220581",
      其它区: "220583",
      通化县: "220521"
    },
    延边朝鲜族自治州: {
      安图县: "222426",
      敦化市: "222403",
      和龙市: "222406",
      珲春市: "222404",
      龙井市: "222405",
      其它区: "222427",
      图们市: "222402",
      汪清县: "222424",
      延吉市: "222401"
    }
  },
  广西: {
    百色市: {
      德保县: "451024",
      靖西市: "451025",
      乐业县: "451028",
      凌云县: "451027",
      隆林各族自治县: "451031",
      那坡县: "451026",
      平果县: "451023",
      其它区: "451032",
      田东县: "451022",
      田林县: "451029",
      田阳县: "451021",
      西林县: "451030",
      右江区: "451002"
    },
    北海市: {
      海城区: "450502",
      合浦县: "450521",
      其它区: "450522",
      铁山港区: "450512",
      银海区: "450503"
    },
    崇左市: {
      大新县: "451424",
      扶绥县: "451421",
      江州区: "451402",
      龙州县: "451423",
      宁明县: "451422",
      凭祥市: "451481",
      其它区: "451482",
      天等县: "451425"
    },
    防城港市: {
      东兴市: "450681",
      防城区: "450603",
      港口区: "450602",
      其它区: "450682",
      上思县: "450621"
    },
    贵港市: {
      港北区: "450802",
      港南区: "450803",
      桂平市: "450881",
      平南县: "450821",
      覃塘区: "450804",
      其它区: "450882"
    },
    桂林市: {
      叠彩区: "450303",
      恭城瑶族自治县: "450332",
      灌阳县: "450327",
      灵川县: "450323",
      临桂区: "450322",
      荔浦县: "450331",
      龙胜各族自治县: "450328",
      平乐县: "450330",
      其它区: "450333",
      七星区: "450305",
      全州县: "450324",
      象山区: "450304",
      兴安县: "450325",
      秀峰区: "450302",
      阳朔县: "450321",
      雁山区: "450311",
      永福县: "450326",
      资源县: "450329"
    },
    河池市: {
      巴马瑶族自治县: "451227",
      大化瑶族自治县: "451229",
      东兰县: "451224",
      都安瑶族自治县: "451228",
      凤山县: "451223",
      环江毛南族自治县: "451226",
      金城江区: "451202",
      罗城仫佬族自治县: "451225",
      南丹县: "451221",
      其它区: "451282",
      天峨县: "451222",
      宜州区: "451281"
    },
    贺州市: {
      八步区: "451102",
      富川瑶族自治县: "451123",
      平桂区: "451103",
      其它区: "451124",
      昭平县: "451121",
      钟山县: "451122"
    },
    来宾市: {
      合山市: "451381",
      金秀瑶族自治县: "451324",
      其它区: "451382",
      武宣县: "451323",
      象州县: "451322",
      忻城县: "451321",
      兴宾区: "451302"
    },
    柳州市: {
      城中区: "450202",
      柳北区: "450205",
      柳城县: "450222",
      柳江区: "450221",
      柳南区: "450204",
      鹿寨县: "450223",
      其它区: "450227",
      融安县: "450224",
      融水苗族自治县: "450225",
      三江侗族自治县: "450226",
      鱼峰区: "450203"
    },
    南宁市: {
      宾阳县: "450126",
      横县: "450127",
      江南区: "450105",
      良庆区: "450108",
      隆安县: "450123",
      马山县: "450124",
      青秀区: "450103",
      其它区: "450128",
      上林县: "450125",
      武鸣区: "450122",
      兴宁区: "450102",
      西乡塘区: "450107",
      邕宁区: "450109"
    },
    钦州市: {
      灵山县: "450721",
      浦北县: "450722",
      钦北区: "450703",
      钦南区: "450702",
      其它区: "450723"
    },
    梧州市: {
      苍梧县: "450421",
      岑溪市: "450481",
      长洲区: "450405",
      龙圩区: "450406",
      蒙山县: "450423",
      其它区: "450482",
      藤县: "450422",
      万秀区: "450403"
    },
    玉林市: {
      北流市: "450981",
      博白县: "450923",
      福绵区: "450903",
      陆川县: "450922",
      其它区: "450982",
      容县: "450921",
      兴业县: "450924",
      玉州区: "450902"
    }
  },
  海南: {
    白沙黎族自治县: {
      牙叉镇: "469025100000",
      七坊镇: "469025101000",
      邦溪镇: "469025102000",
      打安镇: "469025103000",
      细水乡: "469025200000",
      元门乡: "469025201000",
      南开乡: "469025202000",
      阜龙乡: "469025203000",
      青松乡: "469025204000",
      金波乡: "469025205000",
      荣邦乡: "469025206000"
    },
    保亭黎族苗族自治县: {
      保城镇: "469029100000",
      什玲镇: "469029101000",
      加茂镇: "469029102000",
      响水镇: "469029103000",
      新政镇: "469029104000",
      三道镇: "469029105000",
      县直辖村级区划: "469029198000",
      六弓乡: "469029200000",
      南林乡: "469029201000",
      毛感乡: "469029202000"
    },
    昌江黎族自治县: {
      石碌镇: "469026100000",
      叉河镇: "469026101000",
      十月田镇: "469026102000",
      乌烈镇: "469026103000",
      昌化镇: "469026104000",
      海尾镇: "469026105000",
      七叉镇: "469026106000",
      王下乡: "469026200000",
      国营霸王岭林场: "469026500000"
    },
    澄迈县: {
      金江镇: "469023100000",
      老城镇: "469023101000",
      瑞溪镇: "469023102000",
      永发镇: "469023103000",
      加乐镇: "469023104000",
      文儒镇: "469023105000",
      中兴镇: "469023106000",
      仁兴镇: "469023107000",
      福山镇: "469023108000",
      桥头镇: "469023109000",
      大丰镇: "469023110000",
      国营金安农场: "469023405000"
    },
    儋州市: {
      那大镇: "460400100000",
      和庆镇: "460400101000",
      南丰镇: "460400102000",
      大成镇: "460400103000",
      雅星镇: "460400104000",
      兰洋镇: "460400105000",
      光村镇: "460400106000",
      木棠镇: "460400107000",
      海头镇: "460400108000",
      峨蔓镇: "460400109000",
      王五镇: "460400111000",
      白马井镇: "460400112000",
      中和镇: "460400113000",
      排浦镇: "460400114000",
      东成镇: "460400115000",
      新州镇: "460400116000",
      洋浦经济开发区: "460400499000",
      华南热作学院: "	460400500000"
    },
    定安县: {
      定城镇: "469021100000",
      新竹镇: "469021101000",
      龙湖镇: "469021102000",
      黄竹镇: "469021103000",
      雷鸣镇: "469021104000",
      龙门镇: "469021105000",
      龙河镇: "469021106000",
      岭口镇: "469021107000",
      翰林镇: "469021108000",
      富文镇: "469021109000"
    },
    东方市: {
      八所镇: "469007100000",
      东河镇: "469007101000",
      大田镇: "469007102000",
      感城镇: "469007103000",
      板桥镇: "469007104000",
      三家镇: "469007105000",
      四更镇: "469007106000",
      新龙镇: "469007107000",
      天安乡: "469007200000",
      江边乡: "469007201000",
      东方华侨农场: "469007500000"
    },
    海口市: {
      龙华区: "460106",
      美兰区: "460108",
      琼山区: "460107",
      其它区: "460109",
      秀英区: "460105"
    },
    乐东黎族自治县: {
      抱由镇: "469027100000",
      万冲镇: "469027101000",
      大安镇: "469027102000",
      志仲镇: "469027103000",
      千家镇: "469027104000",
      九所镇: "469027105000",
      利国镇: "469027106000",
      黄流镇: "469027107000",
      佛罗镇: "469027108000",
      尖峰镇: "469027109000",
      莺歌海镇: "469027110000",
      国营尖峰岭林业公司: "469027500000",
      国营莺歌海盐场: "469027501000"
    },
    临高县: {
      临城镇: "469024100000",
      波莲镇: "469024101000",
      东英镇: "469024102000",
      博厚镇: "469024103000",
      皇桐镇: "469024104000",
      多文镇: "469024105000",
      和舍镇: "469024106000",
      南宝镇: "469024107000",
      新盈镇: "469024108000",
      调楼镇: "469024109000",
      国营加来农场: "469024401000"
    },
    陵水黎族自治县: {
      椰林镇: "469028100000",
      光坡镇: "469028101000",
      三才镇: "469028102000",
      英州镇: "469028103000",
      隆广镇: "469028104000",
      文罗镇: "469028105000",
      本号镇: "469028106000",
      新村镇: "469028107000",
      黎安镇: "469028108000",
      提蒙乡: "469028200000",
      群英乡: "469028201000",
      国营吊罗山林业公司: "469028500000"
    },
    琼海市: {
      嘉积镇: "	469002100000",
      万泉镇: "	469002101000",
      石壁镇: "	469002102000",
      中原镇: "	469002103000",
      博鳌镇: "	469002104000",
      阳江镇: "	469002105000",
      龙江镇: "	469002106000",
      潭门镇: "	469002107000",
      塔洋镇: "	469002108000",
      长坡镇: "	469002109000",
      大路镇: "	469002110000",
      会山镇: "	469002111000",
      彬村山华侨农场: "	469002500000"
    },
    琼中黎族苗族自治县: {
      营根镇: "469030100000",
      湾岭镇: "469030101000",
      黎母山镇: "469030102000",
      和平镇: "469030103000",
      长征镇: "469030104000",
      红毛镇: "469030105000",
      中平镇: "469030106000",
      吊罗山乡: "469030200000",
      上安乡: "469030201000",
      什运乡: "469030202000",
      海南省黎母山林场: "469030500000"
    },
    三沙市: {
      南沙群岛: "460322",
      西沙群岛: "460321",
      中沙群岛的岛礁及其海域: "460323"
    },
    三亚市: {
      海棠区: "460202",
      吉阳区: "460203",
      天涯区: "460204",
      崖州区: "460205"
    },
    屯昌县: {
      屯城镇: "469022100000",
      新兴镇: "469022101000",
      枫木镇: "469022102000",
      乌坡镇: "469022103000",
      南吕镇: "469022104000",
      南坤镇: "469022105000",
      坡心镇: "469022106000",
      西昌镇: "469022107000"
    },
    万宁市: {
      万城镇: "	469006100000",
      龙滚镇: "	469006101000",
      和乐镇: "	469006102000",
      后安镇: "	469006103000",
      大茂镇: "	469006104000",
      东澳镇: "	469006105000",
      礼纪镇: "	469006106000",
      长丰镇: "	469006107000",
      山根镇: "	469006108000",
      北大镇: "	469006109000",
      南桥镇: "	469006110000",
      三更罗镇: "	469006111000",
      兴隆华侨农场: "	469006500000",
      地方国营六连林场: "	469006501000"
    },
    文昌市: {
      文城镇: "	469005100000",
      重兴镇: "	469005101000",
      蓬莱镇: "	469005102000",
      会文镇: "	469005103000",
      东路镇: "	469005104000",
      潭牛镇: "	469005105000",
      东阁镇: "	469005106000",
      文教镇: "	469005107000",
      东郊镇: "	469005108000",
      龙楼镇: "	469005109000",
      昌洒镇: "	469005110000",
      翁田镇: "	469005111000",
      抱罗镇: "	469005112000",
      冯坡镇: "	469005113000",
      锦山镇: "	469005114000",
      铺前镇: "	469005115000",
      公坡镇: "	469005116000"
    },
    五指山市: {
      通什镇: "	469001100000",
      南圣镇: "	469001101000",
      毛阳镇: "	469001102000",
      番阳镇: "	469001103000",
      县直辖村级区划: "	469001198000",
      畅好乡: "	469001200000",
      毛道乡: "	469001201000",
      水满乡: "	469001202000"
    }
  },
  甘肃: {
    白银市: {
      白银区: "620402",
      会宁县: "620422",
      景泰县: "620423",
      靖远县: "620421",
      平川区: "620403",
      其它区: "620424"
    },
    定西市: {
      安定区: "621102",
      临洮县: "621124",
      陇西县: "621122",
      岷县: "621126",
      其它区: "621127",
      通渭县: "621121",
      渭源县: "621123",
      漳县: "621125"
    },
    甘南藏族自治州: {
      迭部县: "623024",
      合作市: "623001",
      临潭县: "623021",
      碌曲县: "623026",
      玛曲县: "623025",
      其它区: "623028",
      夏河县: "623027",
      舟曲县: "623023",
      卓尼县: "623022"
    },
    嘉峪关市: {
      新城镇: "620201100000",
      峪泉镇: "620201101000",
      文殊镇: "620201102000",
      雄关区: "620201401000",
      镜铁区: "620201402000",
      长城区: "620201403000"
    },
    金昌市: { 金川区: "620302", 其它区: "620322", 永昌县: "620321" },
    酒泉市: {
      阿克塞哈萨克族自治县: "620924",
      敦煌市: "620982",
      瓜州县: "620922",
      金塔县: "620921",
      其它区: "620983",
      肃北蒙古族自治县: "620923",
      肃州区: "620902",
      玉门市: "620981"
    },
    兰州市: {
      安宁区: "620105",
      城关区: "620102",
      皋兰县: "620122",
      红古区: "620111",
      七里河区: "620103",
      其它区: "620124",
      西固区: "620104",
      永登县: "620121",
      榆中县: "620123",
      兰州新区: "620171"
    },
    临夏回族自治州: {
      东乡族自治县: "622926",
      广河县: "622924",
      和政县: "622925",
      积石山保安族东乡族撒拉族自治县: "622927",
      康乐县: "622922",
      临夏市: "622901",
      临夏县: "622921",
      其它区: "622928",
      永靖县: "622923"
    },
    陇南市: {
      成县: "621221",
      宕昌县: "621223",
      徽县: "621227",
      康县: "621224",
      两当县: "621228",
      礼县: "621226",
      其它区: "621229",
      文县: "621222",
      武都区: "621202",
      西和县: "621225"
    },
    平凉市: {
      崇信县: "620823",
      华亭县: "620824",
      泾川县: "620821",
      静宁县: "620826",
      崆峒区: "620802",
      灵台县: "620822",
      其它区: "620827",
      庄浪县: "620825"
    },
    庆阳市: {
      合水县: "621024",
      华池县: "621023",
      环县: "621022",
      宁县: "621026",
      庆城县: "621021",
      其它区: "621028",
      西峰区: "621002",
      正宁县: "621025",
      镇原县: "621027"
    },
    天水市: {
      甘谷县: "620523",
      麦积区: "620503",
      秦安县: "620522",
      清水县: "620521",
      秦州区: "620502",
      其它区: "620526",
      武山县: "620524",
      张家川回族自治县: "620525"
    },
    武威市: {
      古浪县: "620622",
      凉州区: "620602",
      民勤县: "620621",
      其它区: "620624",
      天祝藏族自治县: "620623"
    },
    张掖市: {
      甘州区: "620702",
      高台县: "620724",
      临泽县: "620723",
      民乐县: "620722",
      其它区: "620726",
      山丹县: "620725",
      肃南裕固族自治县: "620721"
    }
  },
  河北: {
    保定市: {
      竞秀区: "130602000000",
      莲池区: "130606000000",
      满城区: "130607000000",
      清苑区: "130608000000",
      徐水区: "130609000000",
      涞水县: "130623000000",
      阜平县: "130624000000",
      定兴县: "130626000000",
      唐县: "130627000000",
      高阳县: "130628000000",
      容城县: "130629000000",
      涞源县: "130630000000",
      望都县: "130631000000",
      安新县: "130632000000",
      易县: "130633000000",
      曲阳县: "130634000000",
      蠡县: "130635000000",
      顺平县: "130636000000",
      博野县: "130637000000",
      雄县: "130638000000",
      保定高新技术产业开发区: "130671000000",
      保定白沟新城: "130672000000",
      涿州市: "130681000000",
      定州市: "130682000000",
      安国市: "130683000000",
      高碑店市: "130684000000"
    },
    沧州市: {
      泊头市: "130981",
      沧县: "130921",
      东光县: "130923",
      海兴县: "130924",
      河间市: "130984",
      黄骅市: "130983",
      孟村回族自治县: "130930",
      南皮县: "130927",
      青县: "130922",
      其它区: "130985",
      任丘市: "130982",
      肃宁县: "130926",
      吴桥县: "130928",
      献县: "130929",
      新华区: "130902",
      盐山县: "130925",
      运河区: "130903",
      孟村回族自治县: "130930000000",
      河北沧州经济开发区: "130971000000",
      沧州高新技术产业开发区: "130972000000",
      沧州渤海新区: "130973000000"
    },
    承德市: {
      承德县: "130821",
      丰宁满族自治县: "130826",
      宽城满族自治县: "130827",
      隆化县: "130825",
      滦平县: "130824",
      平泉市: "130823",
      其它区: "130829",
      双滦区: "130803",
      双桥区: "130802",
      围场满族蒙古族自治县: "130828",
      承德高新技术产业开发区: "130871",
      兴隆县: "130822",
      鹰手营子矿区: "130804"
    },
    邯郸市: {
      成安县: "130424",
      磁县: "130427",
      丛台区: "130403",
      大名县: "130425",
      肥乡区: "130428",
      峰峰矿区: "130406",
      复兴区: "130404",
      广平县: "130432",
      馆陶县: "130433",
      邯山区: "130402",
      鸡泽县: "130431",
      临漳县: "130423",
      其它区: "130482",
      邱县: "130430",
      曲周县: "130435",
      涉县: "130426",
      魏县: "130434",
      武安市: "130481",
      永年区: "130429",
      邯郸经济技术开发区: "130471",
      邯郸冀南新区: "130473"
    },
    衡水市: {
      安平县: "131125",
      阜城县: "131128",
      故城县: "131126",
      景县: "131127",
      冀州区: "131181",
      其它区: "131183",
      饶阳县: "131124",
      深州市: "131182",
      桃城区: "131102",
      武强县: "131123",
      武邑县: "131122",
      枣强县: "131121",
      河北衡水高新技术产业开发区: "131171",
      衡水滨湖新区: "131172"
    },
    廊坊市: {
      安次区: "131002",
      霸州市: "131081",
      大厂回族自治县: "131028",
      大城县: "131025",
      广阳区: "131003",
      固安县: "131022",
      其它区: "131083",
      三河市: "131082",
      文安县: "131026",
      香河县: "131024",
      永清县: "131023",
      廊坊经济技术开发区: "131071"
    },
    秦皇岛市: {
      海港区: "130302000000",
      山海关区: "130303000000",
      北戴河区: "130304000000",
      抚宁区: "130306000000",
      青龙满族自治县: "130321000000",
      昌黎县: "130322000000",
      卢龙县: "130324000000",
      秦皇岛市经济技术开发区: "130371000000",
      北戴河新区: "130372000000"
    },
    石家庄市: {
      长安区: "130102",
      藁城区: "130182",
      高邑县: "130127",
      井陉矿区: "130107",
      井陉县: "130121",
      晋州市: "130183",
      灵寿县: "130126",
      栾城区: "130124",
      鹿泉区: "130185",
      平山县: "130131",
      桥西区: "130104",
      其它区: "130186",
      深泽县: "130128",
      无极县: "130130",
      行唐县: "130125",
      新华区: "130105",
      辛集市: "130181",
      新乐市: "130184",
      元氏县: "130132",
      裕华区: "130108",
      赞皇县: "130129",
      赵县: "130133",
      正定县: "130123",
      石家庄高新技术产业开发区: "130171",
      石家庄循环化工园区: "1301720"
    },
    唐山市: {
      曹妃甸区: "130230",
      丰南区: "130207",
      丰润区: "130208",
      古冶区: "130204",
      开平区: "130205",
      乐亭县: "130225",
      滦南县: "130224",
      滦县: "130223",
      路北区: "130203",
      路南区: "130202",
      迁安市: "130283",
      迁西县: "130227",
      其它区: "130284",
      玉田县: "130229",
      遵化市: "130281",
      河北唐山芦台经济开发区: "130271",
      唐山市汉沽管理区: "130272",
      唐山高新技术产业开发区: "130273",
      河北唐山海港经济开发区: "130274",
      滦州市: "130284"
    },
    邢台市: {
      襄都区: "130502000000",
      信都区: "130503000000",
      任泽区: "130505000000",
      南和区: "130506000000",
      临城县: "130522000000",
      内丘县: "130523000000",
      柏乡县: "130524000000",
      隆尧县: "130525000000",
      宁晋县: "130528000000",
      巨鹿县: "130529000000",
      新河县: "130530000000",
      广宗县: "130531000000",
      平乡县: "130532000000",
      威县: "130533000000",
      清河县: "130534000000",
      临西县: "130535000000",
      河北邢台经济开发区: "130571000000",
      南宫市: "130581000000",
      沙河市: "130582000000"
    },
    张家口市: {
      桥东区: "130702000000",
      桥西区: "130703000000",
      宣化区: "130705000000",
      下花园区: "130706000000",
      万全区: "130708000000",
      崇礼区: "130709000000",
      张北县: "130722000000",
      康保县: "130723000000",
      沽源县: "130724000000",
      尚义县: "130725000000",
      蔚县: "130726000000",
      阳原县: "130727000000",
      怀安县: "130728000000",
      怀来县: "130730000000",
      涿鹿县: "130731000000",
      赤城县: "130732000000",
      张家口经济开发区: "130771000000",
      张家口市察北管理区: "130772000000",
      张家口市塞北管理区: "130773000000"
    }
  },
  // --
  云南: {
    保山市: {
      昌宁县: "530524",
      龙陵县: "530523",
      隆阳区: "530502",
      其它区: "530525",
      施甸县: "530521",
      腾冲市: "530522"
    },
    楚雄彝族自治州: {
      楚雄市: "532301",
      大姚县: "532326",
      禄丰县: "532331",
      牟定县: "532323",
      南华县: "532324",
      其它区: "532332",
      双柏县: "532322",
      武定县: "532329",
      姚安县: "532325",
      永仁县: "532327",
      元谋县: "532328"
    },
    大理白族自治州: {
      宾川县: "532924",
      大理市: "532901",
      洱源县: "532930",
      鹤庆县: "532932",
      剑川县: "532931",
      弥渡县: "532925",
      南涧彝族自治县: "532926",
      其它区: "532933",
      巍山彝族回族自治县: "532927",
      祥云县: "532923",
      漾濞彝族自治县: "532922",
      永平县: "532928",
      云龙县: "532929"
    },
    德宏傣族景颇族自治州: {
      梁河县: "533122",
      陇川县: "533124",
      芒市: "533103",
      其它区: "533125",
      瑞丽市: "533102",
      盈江县: "533123"
    },
    迪庆藏族自治州: {
      德钦县: "533422",
      其它区: "533424",
      维西傈僳族自治县: "533423",
      香格里拉市: "533421"
    },
    红河哈尼族彝族自治州: {
      个旧市: "532501",
      河口瑶族自治县: "532532",
      红河县: "532529",
      建水县: "532524",
      金平苗族瑶族傣族自治县: "532530",
      开远市: "532502",
      泸西县: "532527",
      绿春县: "532531",
      蒙自市: "532522",
      弥勒市: "532526",
      屏边苗族自治县: "532523",
      其它区: "532533",
      石屏县: "532525",
      元阳县: "532528"
    },
    昆明市: {
      安宁市: "530181",
      呈贡区: "530121",
      东川区: "530113",
      富民县: "530124",
      官渡区: "530111",
      晋宁区: "530122",
      禄劝彝族苗族自治县: "530128",
      盘龙区: "530103",
      其它区: "530182",
      石林彝族自治县: "530126",
      嵩明县: "530127",
      五华区: "530102",
      西山区: "530112",
      寻甸回族彝族自治县: "530129",
      宜良县: "530125"
    },
    丽江市: {
      古城区: "530702",
      华坪县: "530723",
      宁蒗彝族自治县: "530724",
      其它区: "530725",
      永胜县: "530722",
      玉龙纳西族自治县: "530721"
    },
    临沧市: {
      沧源佤族自治县: "530927",
      凤庆县: "530921",
      耿马傣族佤族自治县: "530926",
      临翔区: "530902",
      其它区: "530928",
      双江拉祜族佤族布朗族傣族自治县: "530925",
      永德县: "530923",
      云县: "530922",
      镇康县: "530924"
    },
    怒江傈僳族自治州: {
      福贡县: "533323",
      贡山独龙族怒族自治县: "533324",
      兰坪白族普米族自治县: "533325",
      泸水市: "533321",
      其它区: "533326"
    },
    普洱市: {
      江城哈尼族彝族自治县: "530826",
      景东彝族自治县: "530823",
      景谷傣族彝族自治县: "530824",
      澜沧拉祜族自治县: "530828",
      孟连傣族拉祜族佤族自治县: "530827",
      墨江哈尼族自治县: "530822",
      宁洱哈尼族彝族自治县: "530821",
      其它区: "530830",
      思茅区: "530802",
      西盟佤族自治县: "530829",
      镇沅彝族哈尼族拉祜族自治县: "530825"
    },
    曲靖市: {
      富源县: "530325",
      会泽县: "530326",
      陆良县: "530322",
      罗平县: "530324",
      马龙区: "530321",
      麒麟区: "530302",
      其它区: "530382",
      师宗县: "530323",
      宣威市: "530381",
      沾益区: "530328"
    },
    文山壮族苗族自治州: {
      富宁县: "532628",
      广南县: "532627",
      马关县: "532625",
      麻栗坡县: "532624",
      其它区: "532629",
      丘北县: "532626",
      文山市: "532621",
      西畴县: "532623",
      砚山县: "532622"
    },
    西双版纳傣族自治州: {
      景洪市: "532801",
      勐海县: "532822",
      勐腊县: "532823",
      其它区: "532824"
    },
    玉溪市: {
      澄江县: "530422",
      峨山彝族自治县: "530426",
      红塔区: "530402",
      华宁县: "530424",
      江川区: "530421",
      其它区: "530429",
      通海县: "530423",
      新平彝族傣族自治县: "530427",
      易门县: "530425",
      元江哈尼族彝族傣族自治县: "530428"
    },
    昭通市: {
      大关县: "530624",
      鲁甸县: "530621",
      巧家县: "530622",
      其它区: "530631",
      水富县: "530630",
      绥江县: "530626",
      威信县: "530629",
      盐津县: "530623",
      彝良县: "530628",
      永善县: "530625",
      昭阳区: "530602",
      镇雄县: "530627"
    }
  },
  山东: {
    滨州市: {
      滨城区: "371602",
      博兴县: "371625",
      惠民县: "371621",
      其它区: "371627",
      无棣县: "371623",
      阳信县: "371622",
      沾化区: "371624",
      邹平县: "371626"
    },
    德州市: {
      德城区: "371402",
      乐陵市: "371481",
      陵城区: "371421",
      临邑县: "371424",
      宁津县: "371422",
      平原县: "371426",
      齐河县: "371425",
      庆云县: "371423",
      其它区: "371483",
      武城县: "371428",
      夏津县: "371427",
      禹城市: "371482"
    },
    东营市: {
      东营区: "370502",
      广饶县: "370523",
      河口区: "370503",
      垦利区: "370521",
      利津县: "370522",
      其它区: "370591"
    },
    菏泽市: {
      曹县: "371721",
      成武县: "371723",
      定陶区: "371727",
      东明县: "371728",
      鄄城县: "371726",
      巨野县: "371724",
      牡丹区: "371702",
      其它区: "371729",
      单县: "371722",
      郓城县: "371725"
    },
    济南市: {
      长清区: "370113",
      槐荫区: "370104",
      济阳区: "370125",
      历城区: "370112",
      历下区: "370102",
      平阴县: "370124",
      其它区: "370182",
      商河县: "370126",
      市中区: "370103",
      天桥区: "370105",
      章丘区: "370181"
    },
    济宁市: {
      嘉祥县: "370829",
      金乡县: "370828",
      梁山县: "370832",
      市中区: "370884",
      曲阜市: "370881",
      任城区: "370811",
      泗水县: "370831",
      微山县: "370826",
      汶上县: "370830",
      兖州区: "370882",
      鱼台县: "370827",
      邹城市: "370883"
    },
    莱芜市: { 钢城区: "371203", 莱城区: "371202", 其它区: "371204" },
    聊城市: {
      茌平县: "371523",
      东昌府区: "371502",
      东阿县: "371524",
      高唐县: "371526",
      冠县: "371525",
      临清市: "371581",
      其它区: "371582",
      莘县: "371522",
      阳谷县: "371521"
    },
    临沂市: {
      费县: "371325",
      河东区: "371312",
      莒南县: "371327",
      兰陵县: "371324",
      兰山区: "371302",
      临沭县: "371329",
      罗庄区: "371311",
      蒙阴县: "371328",
      平邑县: "371326",
      其它区: "371330",
      郯城县: "371322",
      沂南县: "371321",
      沂水县: "371323"
    },
    青岛市: {
      城阳区: "370214",
      黄岛区: "370211",
      胶州市: "370281",
      即墨区: "370282",
      莱西市: "370285",
      崂山区: "370212",
      李沧区: "370213",
      平度市: "370283",
      其它区: "370286",
      市北区: "370203",
      市南区: "370202"
    },
    日照市: {
      东港区: "371102",
      莒县: "371122",
      岚山区: "371103",
      其它区: "371123",
      五莲县: "371121"
    },
    泰安市: {
      岱岳区: "370903",
      东平县: "370923",
      肥城市: "370983",
      宁阳县: "370921",
      其它区: "370984",
      泰山区: "370902",
      新泰市: "370982"
    },
    潍坊市: {
      安丘市: "370784",
      昌乐县: "370725",
      昌邑市: "370786",
      坊子区: "370704",
      高密市: "370785",
      寒亭区: "370703",
      奎文区: "370705",
      临朐县: "370724",
      青州市: "370781",
      其它区: "370787",
      寿光市: "370783",
      潍城区: "370702",
      诸城市: "370782"
    },
    威海市: {
      环翠区: "371002",
      其它区: "371084",
      荣成市: "371082",
      乳山市: "371083",
      文登区: "371081"
    },
    烟台市: {
      长岛县: "370634",
      福山区: "370611",
      海阳市: "370687",
      莱山区: "370613",
      莱阳市: "370682",
      莱州市: "370683",
      龙口市: "370681",
      牟平区: "370612",
      蓬莱市: "370684",
      其它区: "370688",
      栖霞市: "370686",
      招远市: "370685",
      芝罘区: "370602"
    },
    枣庄市: {
      其它区: "370482",
      山亭区: "370406",
      市中区: "370402",
      台儿庄区: "370405",
      滕州市: "370481",
      薛城区: "370403",
      峄城区: "370404"
    },
    淄博市: {
      博山区: "370304",
      高青县: "370322",
      桓台县: "370321",
      临淄区: "370305",
      其它区: "370324",
      沂源县: "370323",
      张店区: "370303",
      周村区: "370306",
      淄川区: "370302"
    }
  },
  湖南: {
    常德市: {
      安乡县: "430721",
      鼎城区: "430703",
      汉寿县: "430722",
      津市市: "430781",
      临澧县: "430724",
      澧县: "430723",
      其它区: "430782",
      石门县: "430726",
      桃源县: "430725",
      武陵区: "430702"
    },
    长沙市: {
      长沙县: "430121",
      芙蓉区: "430102",
      开福区: "430105",
      浏阳市: "430181",
      宁乡市: "430124",
      其它区: "430182",
      天心区: "430103",
      望城区: "430122",
      岳麓区: "430104",
      雨花区: "430111"
    },
    郴州市: {
      安仁县: "431028",
      北湖区: "431002",
      桂东县: "431027",
      桂阳县: "431021",
      嘉禾县: "431024",
      临武县: "431025",
      其它区: "431082",
      汝城县: "431026",
      苏仙区: "431003",
      宜章县: "431022",
      永兴县: "431023",
      资兴市: "431081"
    },
    衡阳市: {
      常宁市: "430482",
      衡东县: "430424",
      衡南县: "430422",
      衡山县: "430423",
      衡阳县: "430421",
      耒阳市: "430481",
      南岳区: "430412",
      祁东县: "430426",
      其它区: "430483",
      石鼓区: "430407",
      雁峰区: "430406",
      蒸湘区: "430408",
      珠晖区: "430405"
    },
    怀化市: {
      辰溪县: "431223",
      鹤城区: "431202",
      洪江市: "431281",
      会同县: "431225",
      靖州苗族侗族自治县: "431229",
      麻阳苗族自治县: "431226",
      其它区: "431282",
      通道侗族自治县: "431230",
      新晃侗族自治县: "431227",
      溆浦县: "431224",
      沅陵县: "431222",
      芷江侗族自治县: "431228",
      中方县: "431221"
    },
    娄底市: {
      冷水江市: "431381",
      涟源市: "431382",
      娄星区: "431302",
      其它区: "431383",
      双峰县: "431321",
      新化县: "431322"
    },
    邵阳市: {
      北塔区: "430511",
      城步苗族自治县: "430529",
      大祥区: "430503",
      洞口县: "430525",
      隆回县: "430524",
      其它区: "430582",
      邵东县: "430521",
      邵阳县: "430523",
      双清区: "430502",
      绥宁县: "430527",
      武冈市: "430581",
      新宁县: "430528",
      新邵县: "430522"
    },
    湘潭市: {
      其它区: "430383",
      韶山市: "430382",
      湘潭县: "430321",
      湘乡市: "430381",
      岳塘区: "430304",
      雨湖区: "430302"
    },
    湘西土家族苗族自治州: {
      保靖县: "433125",
      凤凰县: "433123",
      古丈县: "433126",
      花垣县: "433124",
      吉首市: "433101",
      龙山县: "433130",
      泸溪县: "433122",
      其它区: "433131",
      永顺县: "433127"
    },
    益阳市: {
      安化县: "430923",
      赫山区: "430903",
      南县: "430921",
      其它区: "430982",
      桃江县: "430922",
      沅江市: "430981",
      资阳区: "430902"
    },
    永州市: {
      道县: "431124",
      东安县: "431122",
      江华瑶族自治县: "431129",
      江永县: "431125",
      蓝山县: "431127",
      冷水滩区: "431103",
      零陵区: "431102",
      宁远县: "431126",
      其它区: "431130",
      祁阳县: "431121",
      双牌县: "431123",
      新田县: "431128"
    },
    岳阳市: {
      华容县: "430623",
      君山区: "430611",
      临湘市: "430682",
      汨罗市: "430681",
      平江县: "430626",
      其它区: "430683",
      湘阴县: "430624",
      岳阳楼区: "430602",
      岳阳县: "430621",
      云溪区: "430603"
    },
    张家界市: {
      慈利县: "430821",
      其它区: "430823",
      桑植县: "430822",
      武陵源区: "430811",
      永定区: "430802"
    },
    株洲市: {
      茶陵县: "430224",
      荷塘区: "430202",
      醴陵市: "430281",
      芦淞区: "430203",
      其它区: "430282",
      石峰区: "430204",
      天元区: "430211",
      炎陵县: "430225",
      攸县: "430223",
      株洲县: "430221"
    }
  },
  山西: {
    长治市: {
      长治县: "140421",
      城区: "140482",
      壶关县: "140427",
      郊区: "140483",
      黎城县: "140426",
      潞城市: "140481",
      平顺县: "140425",
      沁县: "140430",
      沁源县: "140431",
      其它区: "140485",
      屯留县: "140424",
      武乡县: "140429",
      襄垣县: "140423",
      长子县: "140428"
    },
    大同市: {
      广灵县: "140223",
      浑源县: "140225",
      灵丘县: "140224",
      平城区: "140213",
      其它区: "140228",
      天镇县: "140222",
      新荣区: "140212",
      阳高县: "140221",
      云冈区: "140214",
      云州区: "140227",
      左云县: "140226"
    },
    晋城市: {
      城区: "140502",
      高平市: "140581",
      陵川县: "140524",
      沁水县: "140521",
      其它区: "140582",
      阳城县: "140522",
      泽州县: "140525"
    },
    晋中市: {
      和顺县: "140723",
      介休市: "140781",
      灵石县: "140729",
      平遥县: "140728",
      其它区: "140782",
      祁县: "140727",
      寿阳县: "140725",
      太谷县: "140726",
      昔阳县: "140724",
      榆次区: "140702",
      榆社县: "140721",
      左权县: "140722"
    },
    临汾市: {
      安泽县: "141026",
      大宁县: "141030",
      汾西县: "141034",
      浮山县: "141027",
      古县: "141025",
      洪洞县: "141024",
      侯马市: "141081",
      霍州市: "141082",
      吉县: "141028",
      蒲县: "141033",
      其它区: "141083",
      曲沃县: "141021",
      襄汾县: "141023",
      乡宁县: "141029",
      隰县: "141031",
      尧都区: "141002",
      翼城县: "141022",
      永和县: "141032"
    },
    吕梁市: {
      方山县: "141128",
      汾阳市: "141182",
      交城县: "141122",
      交口县: "141130",
      岚县: "141127",
      临县: "141124",
      离石区: "141102",
      柳林县: "141125",
      其它区: "141183",
      石楼县: "141126",
      文水县: "141121",
      孝义市: "141181",
      兴县: "141123",
      中阳县: "141129"
    },
    朔州市: {
      怀仁市: "140624",
      平鲁区: "140603",
      其它区: "140625",
      山阴县: "140621",
      朔城区: "140602",
      应县: "140622",
      右玉县: "140623"
    },
    太原市: {
      古交市: "140181",
      尖草坪区: "140108",
      晋源区: "140110",
      娄烦县: "140123",
      清徐县: "140121",
      其它区: "140182",
      万柏林区: "140109",
      小店区: "140105",
      杏花岭区: "140107",
      阳曲县: "140122",
      迎泽区: "140106"
    },
    忻州市: {
      保德县: "140931",
      代县: "140923",
      定襄县: "140921",
      繁峙县: "140924",
      河曲县: "140930",
      静乐县: "140926",
      岢岚县: "140929",
      宁武县: "140925",
      偏关县: "140932",
      其它区: "140982",
      神池县: "140927",
      五台县: "140922",
      五寨县: "140928",
      忻府区: "140902",
      原平市: "140981"
    },
    阳泉市: {
      城区: "140302",
      郊区: "140311",
      矿区: "140303",
      平定县: "140321",
      其它区: "140323",
      盂县: "140322"
    },
    运城市: {
      河津市: "140882",
      绛县: "140826",
      稷山县: "140824",
      临猗县: "140821",
      平陆县: "140829",
      其它区: "140883",
      芮城县: "140830",
      万荣县: "140822",
      闻喜县: "140823",
      夏县: "140828",
      新绛县: "140825",
      盐湖区: "140802",
      永济市: "140881",
      垣曲县: "140827"
    }
  },
  江苏: {
    常州市: {
      金坛区: "320482",
      溧阳市: "320481",
      其它区: "320483",
      天宁区: "320402",
      武进区: "320412",
      新北区: "320411",
      钟楼区: "320404"
    },
    淮安市: {
      洪泽区: "320829",
      淮安区: "320803",
      淮阴区: "320804",
      金湖县: "320831",
      涟水县: "320826",
      清江浦区: "320802",
      其它区: "320832",
      盱眙县: "320830"
    },
    连云港市: {
      东海县: "320722",
      赣榆区: "320721",
      灌南县: "320724",
      灌云县: "320723",
      海州区: "320706",
      连云区: "320703",
      其它区: "320725"
    },
    南京市: {
      高淳区: "320125",
      鼓楼区: "320106",
      江宁区: "320115",
      建邺区: "320105",
      溧水区: "320124",
      六合区: "320116",
      浦口区: "320111",
      秦淮区: "320104",
      其它区: "320126",
      栖霞区: "320113",
      玄武区: "320102",
      雨花台区: "320114"
    },
    南通市: {
      崇川区: "320602",
      港闸区: "320611",
      海安市: "320621",
      海门市: "320684",
      启东市: "320681",
      其它区: "320694",
      如东县: "320623",
      如皋市: "320682",
      通州区: "320612"
    },
    宿迁市: {
      其它区: "321325",
      沭阳县: "321322",
      泗洪县: "321324",
      泗阳县: "321323",
      宿城区: "321302",
      宿豫区: "321311"
    },
    苏州市: {
      常熟市: "320581",
      姑苏区: "320508",
      虎丘区: "320505",
      昆山市: "320583",
      其它区: "320596",
      苏州工业园区: "320586",
      太仓市: "320585",
      吴江区: "320584",
      吴中区: "320506",
      相城区: "320507",
      张家港市: "320582"
    },
    泰州市: {
      高港区: "321203",
      海陵区: "321202",
      姜堰区: "321284",
      靖江市: "321282",
      其它区: "321285",
      泰兴市: "321283",
      兴化市: "321281"
    },
    无锡市: {
      滨湖区: "320211",
      惠山区: "320206",
      江阴市: "320281",
      梁溪区: "320213",
      其它区: "320297",
      新吴区: "320214",
      锡山区: "320205",
      宜兴市: "320282"
    },
    徐州市: {
      丰县: "320321",
      鼓楼区: "320302",
      贾汪区: "320305",
      沛县: "320322",
      邳州市: "320382",
      其它区: "320383",
      泉山区: "320311",
      睢宁县: "320324",
      铜山区: "320323",
      新沂市: "320381",
      云龙区: "320303"
    },
    盐城市: {
      滨海县: "320922",
      大丰区: "320982",
      东台市: "320981",
      阜宁县: "320923",
      建湖县: "320925",
      其它区: "320983",
      射阳县: "320924",
      亭湖区: "320902",
      响水县: "320921",
      盐都区: "320903"
    },
    扬州市: {
      宝应县: "321023",
      高邮市: "321084",
      广陵区: "321002",
      邗江区: "321003",
      江都区: "321088",
      其它区: "321093",
      仪征市: "321081"
    },
    镇江市: {
      丹徒区: "321112",
      丹阳市: "321181",
      京口区: "321102",
      句容市: "321183",
      其它区: "321184",
      润州区: "321111",
      扬中市: "321182"
    }
  },
  广东: {
    潮州市: {
      潮安区: "445121",
      其它区: "445186",
      饶平县: "445122",
      湘桥区: "445102"
    },
    东莞市: {
      东城街道: "441900003000",
      南城街道: "441900004000",
      万江街道: "441900005000",
      莞城街道: "441900006000",
      石碣镇: "441900101000",
      石龙镇: "441900102000",
      茶山镇: "441900103000",
      石排镇: "441900104000",
      企石镇: "441900105000",
      横沥镇: "441900106000",
      桥头镇: "441900107000",
      谢岗镇: "441900108000",
      东坑镇: "441900109000",
      常平镇: "441900110000",
      寮步镇: "441900111000",
      樟木头镇: "441900112000",
      大朗镇: "441900113000",
      黄江镇: "441900114000",
      清溪镇: "441900115000",
      塘厦镇: "441900116000",
      凤岗镇: "441900117000",
      大岭山镇: "441900118000",
      长安镇: "441900119000",
      虎门镇: "441900121000",
      厚街镇: "441900122000",
      沙田镇: "441900123000",
      道滘镇: "441900124000",
      洪梅镇: "441900125000",
      麻涌镇: "441900126000",
      望牛墩镇: "441900127000",
      中堂镇: "441900128000",
      高埗镇: "441900129000",
      松山湖管委会: "441900401000",
      虎门港管委会: "441900402000",
      东莞生态园: "441900403000"
    },
    东沙群岛: "442101",
    佛山市: {
      禅城区: "440604",
      高明区: "440608",
      南海区: "440605",
      其它区: "440609",
      三水区: "440607",
      顺德区: "440606"
    },
    广州市: {
      白云区: "440111",
      从化区: "440184",
      海珠区: "440105",
      花都区: "440114",
      黄埔区: "440112",
      荔湾区: "440103",
      南沙区: "440115",
      番禺区: "440113",
      其它区: "440189",
      天河区: "440106",
      越秀区: "440104",
      增城区: "440183"
    },
    河源市: {
      东源县: "441625",
      和平县: "441624",
      连平县: "441623",
      龙川县: "441622",
      其它区: "441626",
      源城区: "441602",
      紫金县: "441621"
    },
    惠州市: {
      博罗县: "441322",
      惠城区: "441302",
      惠东县: "441323",
      惠阳区: "441303",
      龙门县: "441324",
      其它区: "441325"
    },
    江门市: {
      恩平市: "440785",
      鹤山市: "440784",
      江海区: "440704",
      开平市: "440783",
      蓬江区: "440703",
      其它区: "440786",
      台山市: "440781",
      新会区: "440705"
    },
    揭阳市: {
      惠来县: "445224",
      揭东区: "445221",
      揭西县: "445222",
      普宁市: "445281",
      其它区: "445285",
      榕城区: "445202"
    },
    茂名市: {
      电白区: "440903",
      高州市: "440981",
      化州市: "440982",
      茂南区: "440902",
      其它区: "440984",
      信宜市: "440983"
    },
    梅州市: {
      大埔县: "441422",
      丰顺县: "441423",
      蕉岭县: "441427",
      梅江区: "441402",
      梅县区: "441421",
      平远县: "441426",
      其它区: "441482",
      五华县: "441424",
      兴宁市: "441481"
    },
    清远市: {
      佛冈县: "441821",
      连南瑶族自治县: "441826",
      连山壮族瑶族自治县: "441825",
      连州市: "441882",
      清城区: "441802",
      清新区: "441827",
      其它区: "441883",
      阳山县: "441823",
      英德市: "441881"
    },
    汕头市: {
      潮南区: "440514",
      潮阳区: "440513",
      澄海区: "440515",
      濠江区: "440512",
      金平区: "440511",
      龙湖区: "440507",
      南澳县: "440523",
      其它区: "440524"
    },
    汕尾市: {
      城区: "441502",
      海丰县: "441521",
      陆丰市: "441581",
      陆河县: "441523",
      其它区: "441582"
    },
    韶关市: {
      乐昌市: "440281",
      南雄市: "440282",
      其它区: "440283",
      曲江区: "440205",
      仁化县: "440224",
      乳源瑶族自治县: "440232",
      始兴县: "440222",
      翁源县: "440229",
      武江区: "440203",
      新丰县: "440233",
      浈江区: "440204"
    },
    深圳市: {
      宝安区: "440306",
      福田区: "440304",
      光明区: "440320",
      龙岗区: "440307",
      龙华区: "440311",
      罗湖区: "440303",
      南山区: "440305",
      坪山区: "440310",
      其它区: "440309",
      盐田区: "440308",
      大鹏新区: "440307010000"
    },
    阳江市: {
      江城区: "441702",
      其它区: "441782",
      阳春市: "441781",
      阳东区: "441723",
      阳西县: "441721"
    },
    云浮市: {
      罗定市: "445381",
      其它区: "445382",
      新兴县: "445321",
      云安区: "445323",
      郁南县: "445322",
      云城区: "445302"
    },
    湛江市: {
      赤坎区: "440802",
      雷州市: "440882",
      廉江市: "440881",
      麻章区: "440811",
      坡头区: "440804",
      其它区: "440884",
      遂溪县: "440823",
      吴川市: "440883",
      霞山区: "440803",
      徐闻县: "440825"
    },
    肇庆市: {
      德庆县: "441226",
      鼎湖区: "441203",
      端州区: "441202",
      封开县: "441225",
      高要区: "441283",
      广宁县: "441223",
      怀集县: "441224",
      其它区: "441285",
      四会市: "441284"
    },
    中山市: {
      石岐区街道: "442000001000",
      东区街道: "442000002000",
      火炬开发区街道: "442000003000",
      西区街道: "442000004000",
      南区街道: "442000005000",
      五桂山街道: "442000006000",
      小榄镇: "442000100000",
      黄圃镇: "442000101000",
      民众镇: "442000102000",
      东凤镇: "442000103000",
      东升镇: "442000104000",
      古镇镇: "442000105000",
      沙溪镇: "442000106000",
      坦洲镇: "442000107000",
      港口镇: "442000108000",
      三角镇: "442000109000",
      横栏镇: "442000110000",
      南头镇: "442000111000",
      阜沙镇: "442000112000",
      南朗镇: "442000113000",
      三乡镇: "442000114000",
      板芙镇: "442000115000",
      大涌镇: "442000116000",
      神湾镇: "442000117000"
    },
    珠海市: {
      斗门区: "440403",
      金湾区: "440404",
      其它区: "440488",
      香洲区: "440402"
    }
  },
  黑龙江: {
    大庆市: {
      大同区: "230606",
      杜尔伯特蒙古族自治县: "230624",
      红岗区: "230605",
      林甸县: "230623",
      龙凤区: "230603",
      其它区: "230625",
      让胡路区: "230604",
      萨尔图区: "230602",
      肇源县: "230622",
      肇州县: "230621"
    },
    大兴安岭地区: {
      呼玛县: "232721",
      加格达奇区: "232724",
      漠河市: "232723",
      其它区: "232725",
      塔河县: "232722"
    },
    哈尔滨市: {
      阿城区: "230181",
      巴彦县: "230126",
      宾县: "230125",
      道里区: "230102",
      道外区: "230104",
      方正县: "230124",
      呼兰区: "230111",
      木兰县: "230127",
      南岗区: "230103",
      平房区: "230108",
      其它区: "230186",
      尚志市: "230183",
      双城区: "230182",
      松北区: "230109",
      通河县: "230128",
      五常市: "230184",
      香坊区: "230106",
      延寿县: "230129",
      依兰县: "230123"
    },
    鹤岗市: {
      东山区: "230406",
      工农区: "230403",
      萝北县: "230421",
      南山区: "230404",
      其它区: "230423",
      绥滨县: "230422",
      向阳区: "230402",
      兴安区: "230405",
      兴山区: "230407"
    },
    黑河市: {
      爱辉区: "231102",
      北安市: "231181",
      嫩江县: "231121",
      其它区: "231183",
      孙吴县: "231124",
      五大连池市: "231182",
      逊克县: "231123"
    },
    佳木斯市: {
      东风区: "230805",
      富锦市: "230882",
      抚远市: "230833",
      桦川县: "230826",
      桦南县: "230822",
      郊区: "230811",
      前进区: "230804",
      其它区: "230883",
      汤原县: "230828",
      同江市: "230881",
      向阳区: "230803"
    },
    鸡西市: {
      城子河区: "230306",
      滴道区: "230304",
      恒山区: "230303",
      虎林市: "230381",
      鸡东县: "230321",
      鸡冠区: "230302",
      梨树区: "230305",
      麻山区: "230307",
      密山市: "230382",
      其它区: "230383"
    },
    牡丹江市: {
      爱民区: "231004",
      东安区: "231002",
      东宁市: "231024",
      海林市: "231083",
      林口县: "231025",
      穆棱市: "231085",
      宁安市: "231084",
      其它区: "231086",
      绥芬河市: "231081",
      西安区: "231005",
      阳明区: "231003"
    },
    齐齐哈尔市: {
      昂昂溪区: "230205",
      拜泉县: "230231",
      富拉尔基区: "230206",
      富裕县: "230227",
      甘南县: "230225",
      建华区: "230203",
      克东县: "230230",
      克山县: "230229",
      龙江县: "230221",
      龙沙区: "230202",
      梅里斯达斡尔族区: "230208",
      讷河市: "230281",
      碾子山区: "230207",
      其它区: "230282",
      泰来县: "230224",
      铁锋区: "230204",
      依安县: "230223"
    },
    七台河市: {
      勃利县: "230921",
      茄子河区: "230904",
      其它区: "230922",
      桃山区: "230903",
      新兴区: "230902"
    },
    双鸭山市: {
      宝清县: "230523",
      宝山区: "230506",
      尖山区: "230502",
      集贤县: "230521",
      岭东区: "230503",
      其它区: "230525",
      饶河县: "230524",
      四方台区: "230505",
      友谊县: "230522"
    },
    绥化市: {
      安达市: "231281",
      北林区: "231202",
      海伦市: "231283",
      兰西县: "231222",
      明水县: "231225",
      庆安县: "231224",
      青冈县: "231223",
      其它区: "231284",
      绥棱县: "231226",
      望奎县: "231221",
      肇东市: "231282"
    },
    伊春市: {
      翠峦区: "230706",
      带岭区: "230713",
      红星区: "230715",
      嘉荫县: "230722",
      金山屯区: "230709",
      美溪区: "230708",
      南岔区: "230703",
      其它区: "230782",
      上甘岭区: "230716",
      汤旺河区: "230712",
      铁力市: "230781",
      乌马河区: "230711",
      乌伊岭区: "230714",
      五营区: "230710",
      西林区: "230705",
      新青区: "230707",
      伊春区: "230702",
      友好区: "230704"
    }
  },
  湖北: {
    恩施土家族苗族自治州: {
      巴东县: "422823",
      恩施市: "422801",
      鹤峰县: "422828",
      建始县: "422822",
      来凤县: "422827",
      利川市: "422802",
      其它区: "422829",
      咸丰县: "422826",
      宣恩县: "422825"
    },
    鄂州市: {
      鄂城区: "420704",
      华容区: "420703",
      梁子湖区: "420702",
      其它区: "420705"
    },
    黄冈市: {
      红安县: "421122",
      黄梅县: "421127",
      黄州区: "421102",
      罗田县: "421123",
      麻城市: "421181",
      蕲春县: "421126",
      其它区: "421183",
      团风县: "421121",
      武穴市: "421182",
      浠水县: "421125",
      英山县: "421124"
    },
    黄石市: {
      大冶市: "420281",
      黄石港区: "420202",
      其它区: "420282",
      铁山区: "420205",
      下陆区: "420204",
      西塞山区: "420203",
      阳新县: "420222"
    },
    荆门市: {
      东宝区: "420802",
      掇刀区: "420804",
      京山市: "420821",
      其它区: "420882",
      沙洋县: "420822",
      钟祥市: "420881"
    },
    荆州市: {
      公安县: "421022",
      洪湖市: "421083",
      江陵县: "421024",
      监利县: "421023",
      荆州区: "421003",
      其它区: "421088",
      沙市区: "421002",
      石首市: "421081",
      松滋市: "421087"
    },
    潜江市: {
      园林街道: "429005001000",
      杨市街道: "429005002000",
      周矶街道: "429005003000",
      广华街道: "429005004000",
      泰丰街道: "429005005000",
      高场街道: "429005006000",
      竹根滩镇: "429005100000",
      渔洋镇: "429005101000",
      王场镇: "429005102000",
      高石碑镇: "429005103000",
      熊口镇: "429005104000",
      老新镇: "429005105000",
      浩口镇: "429005106000",
      积玉口镇: "429005107000",
      张金镇: "429005108000",
      龙湾镇: "429005109000"
    },
    神农架林区: {
      松柏镇: "429021100000",
      阳日镇: "429021101000",
      木鱼镇: "429021102000",
      红坪镇: "429021103000",
      新华镇: "429021104000",
      九湖镇: "429021105000",
      宋洛乡: "429021200000",
      下谷坪土家族乡: "429021202000"
    },
    十堰市: {
      丹江口市: "420381",
      房县: "420325",
      茅箭区: "420302",
      其它区: "420383",
      郧西县: "420322",
      郧阳区: "420321",
      张湾区: "420303",
      竹山县: "420323",
      竹溪县: "420324"
    },
    随州市: {
      广水市: "421381",
      其它区: "421382",
      随县: "421321",
      曾都区: "421302"
    },
    天门市: {
      竟陵街道: "429006001000",
      侨乡街道: "429006002000",
      杨林街道: "429006003000",
      多宝镇: "429006100000",
      拖市镇: "429006101000",
      张港镇: "429006102000",
      蒋场镇: "429006103000",
      汪场镇: "429006104000",
      渔薪镇: "429006105000",
      黄潭镇: "429006106000",
      岳口镇: "429006107000",
      横林镇: "429006108000",
      彭市镇: "429006109000",
      麻洋镇: "429006110000",
      多祥镇: "429006111000",
      干驿镇: "429006112000",
      马湾镇: "429006113000",
      卢市镇: "429006114000",
      小板镇: "429006115000",
      九真镇: "429006116000",
      皂市镇: "429006118000",
      胡市镇: "429006119000",
      石河镇: "429006120000",
      佛子山镇: "429006121000",
      净潭乡: "429006201000"
    },
    武汉市: {
      蔡甸区: "420114",
      东西湖区: "420112",
      汉南区: "420113",
      汉阳区: "420105",
      洪山区: "420111",
      黄陂区: "420116",
      江岸区: "420102",
      江汉区: "420103",
      江夏区: "420115",
      硚口区: "420104",
      青山区: "420107",
      其它区: "420118",
      武昌区: "420106",
      新洲区: "420117"
    },
    襄阳市: {
      保康县: "420626",
      樊城区: "420606",
      谷城县: "420625",
      老河口市: "420682",
      南漳县: "420624",
      其它区: "420685",
      襄城区: "420602",
      襄州区: "420607",
      宜城市: "420684",
      枣阳市: "420683"
    },
    咸宁市: {
      赤壁市: "421281",
      崇阳县: "421223",
      嘉鱼县: "421221",
      其它区: "421283",
      通城县: "421222",
      通山县: "421224",
      咸安区: "421202"
    },
    仙桃市: {
      沙嘴街道: "429004001000",
      干河街道: "429004002000",
      龙华山街道: "429004003000",
      郑场镇: "429004100000",
      毛嘴镇: "429004101000",
      豆河镇: "429004102000",
      三伏潭镇: "429004103000",
      胡场镇: "429004104000",
      长倘口镇: "429004105000",
      西流河镇: "429004106000",
      沙湖镇: "429004107000",
      杨林尾镇: "429004108000",
      彭场镇: "429004109000",
      张沟镇: "429004110000",
      郭河镇: "429004111000",
      沔城回族镇: "429004112000",
      通海口镇: "429004113000",
      陈场镇: "429004114000",
      工业园区: "429004400000",
      九合垸原种场: "429004401000",
      沙湖原种场: "429004402000",
      五湖渔场: "429004404000",
      赵西垸林场: "429004405000",
      畜禽良种场: "429004407000",
      排湖风景区: "429004408000"
    },
    孝感市: {
      安陆市: "420982",
      大悟县: "420922",
      汉川市: "420984",
      其它区: "420985",
      孝昌县: "420921",
      孝南区: "420902",
      应城市: "420981",
      云梦县: "420923"
    },
    宜昌市: {
      长阳土家族自治县: "420528",
      当阳市: "420582",
      点军区: "420504",
      其它区: "420584",
      五峰土家族自治县: "420529",
      伍家岗区: "420503",
      猇亭区: "420505",
      西陵区: "420502",
      兴山县: "420526",
      宜都市: "420581",
      夷陵区: "420506",
      远安县: "420525",
      枝江市: "420583",
      秭归县: "420527"
    }
  },
  福建: {
    福州市: {
      仓山区: "350104",
      福清市: "350181",
      鼓楼区: "350102",
      晋安区: "350111",
      连江县: "350122",
      罗源县: "350123",
      马尾区: "350105",
      闽侯县: "350121",
      闽清县: "350124",
      平潭县: "350128",
      其它区: "350183",
      台江区: "350103",
      永泰县: "350125",
      长乐区: "350182"
    },
    龙岩市: {
      长汀县: "350821",
      连城县: "350825",
      其它区: "350882",
      上杭县: "350823",
      武平县: "350824",
      新罗区: "350802",
      永定区: "350822",
      漳平市: "350881"
    },
    南平市: {
      光泽县: "350723",
      建瓯市: "350783",
      建阳区: "350784",
      浦城县: "350722",
      其它区: "350785",
      邵武市: "350781",
      顺昌县: "350721",
      松溪县: "350724",
      武夷山市: "350782",
      延平区: "350702",
      政和县: "350725"
    },
    宁德市: {
      福安市: "350981",
      福鼎市: "350982",
      古田县: "350922",
      蕉城区: "350902",
      屏南县: "350923",
      其它区: "350983",
      寿宁县: "350924",
      霞浦县: "350921",
      柘荣县: "350926",
      周宁县: "350925"
    },
    莆田市: {
      城厢区: "350302",
      涵江区: "350303",
      荔城区: "350304",
      其它区: "350323",
      仙游县: "350322",
      秀屿区: "350305"
    },
    泉州市: {
      安溪县: "350524",
      德化县: "350526",
      丰泽区: "350503",
      惠安县: "350521",
      晋江市: "350582",
      金门县: "350527",
      鲤城区: "350502",
      洛江区: "350504",
      南安市: "350583",
      其它区: "350584",
      泉港区: "350505",
      石狮市: "350581",
      永春县: "350525"
    },
    三明市: {
      大田县: "350425",
      将乐县: "350428",
      建宁县: "350430",
      梅列区: "350402",
      明溪县: "350421",
      宁化县: "350424",
      清流县: "350423",
      其它区: "350482",
      三元区: "350403",
      沙县: "350427",
      泰宁县: "350429",
      永安市: "350481",
      尤溪县: "350426"
    },
    厦门市: {
      海沧区: "350205",
      湖里区: "350206",
      集美区: "350211",
      其它区: "350214",
      思明区: "350203",
      同安区: "350212",
      翔安区: "350213"
    },
    漳州市: {
      长泰县: "350625",
      东山县: "350626",
      华安县: "350629",
      龙海市: "350681",
      龙文区: "350603",
      南靖县: "350627",
      平和县: "350628",
      其它区: "350682",
      芗城区: "350602",
      云霄县: "350622",
      漳浦县: "350623",
      诏安县: "350624"
    }
  },
  江西: {
    抚州市: {
      崇仁县: "361024",
      东乡区: "361029",
      广昌县: "361030",
      金溪县: "361027",
      乐安县: "361025",
      黎川县: "361022",
      临川区: "361002",
      南城县: "361021",
      南丰县: "361023",
      其它区: "361031",
      宜黄县: "361026",
      资溪县: "361028"
    },
    赣州市: {
      安远县: "360726",
      崇义县: "360725",
      大余县: "360723",
      定南县: "360728",
      赣县区: "360721",
      会昌县: "360733",
      龙南县: "360727",
      南康区: "360782",
      宁都县: "360730",
      其它区: "360783",
      全南县: "360729",
      瑞金市: "360781",
      上犹县: "360724",
      石城县: "360735",
      信丰县: "360722",
      兴国县: "360732",
      寻乌县: "360734",
      于都县: "360731",
      章贡区: "360702"
    },
    吉安市: {
      安福县: "360829",
      吉安县: "360821",
      井冈山市: "360881",
      吉水县: "360822",
      吉州区: "360802",
      青原区: "360803",
      其它区: "360882",
      遂川县: "360827",
      泰和县: "360826",
      万安县: "360828",
      峡江县: "360823",
      新干县: "360824",
      永丰县: "360825",
      永新县: "360830"
    },
    景德镇市: {
      昌江区: "360202",
      浮梁县: "360222",
      乐平市: "360281",
      其它区: "360282",
      珠山区: "360203"
    },
    九江市: {
      柴桑区: "360421",
      德安县: "360426",
      都昌县: "360428",
      共青城市: "360483",
      湖口县: "360429",
      濂溪区: "360402",
      庐山市: "360427",
      彭泽县: "360430",
      其它区: "360482",
      瑞昌市: "360481",
      武宁县: "360423",
      修水县: "360424",
      浔阳区: "360403",
      永修县: "360425"
    },
    南昌市: {
      安义县: "360123",
      东湖区: "360102",
      进贤县: "360124",
      南昌县: "360121",
      青山湖区: "360111",
      青云谱区: "360104",
      其它区: "360128",
      湾里区: "360105",
      西湖区: "360103",
      新建区: "360122"
    },
    萍乡市: {
      安源区: "360302",
      莲花县: "360321",
      芦溪县: "360323",
      其它区: "360324",
      上栗县: "360322",
      湘东区: "360313"
    },
    上饶市: {
      德兴市: "361181",
      广丰区: "361122",
      横峰县: "361125",
      鄱阳县: "361128",
      其它区: "361182",
      上饶县: "361121",
      万年县: "361129",
      婺源县: "361130",
      信州区: "361102",
      铅山县: "361124",
      弋阳县: "361126",
      余干县: "361127",
      玉山县: "361123"
    },
    新余市: { 分宜县: "360521", 其它区: "360522", 渝水区: "360502" },
    宜春市: {
      丰城市: "360981",
      奉新县: "360921",
      高安市: "360983",
      靖安县: "360925",
      其它区: "360984",
      上高县: "360923",
      铜鼓县: "360926",
      万载县: "360922",
      宜丰县: "360924",
      袁州区: "360902",
      樟树市: "360982"
    },
    鹰潭市: {
      贵溪市: "360681",
      其它区: "360682",
      月湖区: "360602",
      余江区: "360622"
    }
  },
  青海: {
    果洛藏族自治州: {
      班玛县: "632622",
      达日县: "632624",
      甘德县: "632623",
      久治县: "632625",
      玛多县: "632626",
      玛沁县: "632621",
      其它区: "632627"
    },
    海北藏族自治州: {
      刚察县: "632224",
      海晏县: "632223",
      门源回族自治县: "632221",
      祁连县: "632222",
      其它区: "632225"
    },
    海东市: {
      化隆回族自治县: "632127",
      互助土族自治县: "632126",
      乐都区: "632123",
      民和回族土族自治县: "632122",
      平安区: "632121",
      其它区: "632129",
      循化撒拉族自治县: "632128"
    },
    海南藏族自治州: {
      共和县: "632521",
      贵德县: "632523",
      贵南县: "632525",
      其它区: "632526",
      同德县: "632522",
      兴海县: "632524"
    },
    海西蒙古族藏族自治州: {
      德令哈市: "632802",
      都兰县: "632822",
      格尔木市: "632801",
      海西蒙古族藏族自治州直辖: "632825",
      茫崖市: "632803",
      其它区: "632824",
      天峻县: "632823",
      乌兰县: "632821"
    },
    黄南藏族自治州: {
      河南蒙古族自治县: "632324",
      尖扎县: "632322",
      其它区: "632325",
      同仁县: "632321",
      泽库县: "632323"
    },
    西宁市: {
      城北区: "630105",
      城东区: "630102",
      城西区: "630104",
      城中区: "630103",
      大通回族土族自治县: "630121",
      湟源县: "630123",
      湟中县: "630122",
      其它区: "630124"
    },
    玉树藏族自治州: {
      称多县: "632723",
      囊谦县: "632725",
      其它区: "632727",
      曲麻莱县: "632726",
      玉树市: "632721",
      杂多县: "632722",
      治多县: "632724"
    }
  },
  宁夏: {
    固原市: {
      泾源县: "640424",
      隆德县: "640423",
      彭阳县: "640425",
      其它区: "640426",
      西吉县: "640422",
      原州区: "640402"
    },
    石嘴山市: {
      大武口区: "640202",
      惠农区: "640205",
      平罗县: "640221",
      其它区: "640222"
    },
    吴忠市: {
      红寺堡区: "640303",
      利通区: "640302",
      青铜峡市: "640381",
      其它区: "640382",
      同心县: "640324",
      盐池县: "640323"
    },
    银川市: {
      贺兰县: "640122",
      金凤区: "640106",
      灵武市: "640181",
      其它区: "640182",
      兴庆区: "640104",
      西夏区: "640105",
      永宁县: "640121"
    },
    中卫市: {
      海原县: "640522",
      其它区: "640523",
      沙坡头区: "640502",
      中宁县: "640521"
    }
  },
  浙江: {
    杭州市: {
      滨江区: "330108",
      淳安县: "330127",
      富阳区: "330183",
      拱墅区: "330105",
      建德市: "330182",
      江干区: "330104",
      临安区: "330185",
      其它区: "330186",
      上城区: "330102",
      桐庐县: "330122",
      下城区: "330103",
      萧山区: "330109",
      西湖区: "330106",
      余杭区: "330110"
    },
    湖州市: {
      安吉县: "330523",
      长兴县: "330522",
      德清县: "330521",
      南浔区: "330503",
      其它区: "330524",
      吴兴区: "330502"
    },
    嘉兴市: {
      海宁市: "330481",
      海盐县: "330424",
      嘉善县: "330421",
      南湖区: "330402",
      平湖市: "330482",
      其它区: "330484",
      桐乡市: "330483",
      秀洲区: "330411"
    },
    金华市: {
      东阳市: "330783",
      金东区: "330703",
      兰溪市: "330781",
      磐安县: "330727",
      浦江县: "330726",
      其它区: "330785",
      婺城区: "330702",
      武义县: "330723",
      义乌市: "330782",
      永康市: "330784"
    },
    丽水市: {
      景宁畲族自治县: "331127",
      缙云县: "331122",
      莲都区: "331102",
      龙泉市: "331181",
      青田县: "331121",
      庆元县: "331126",
      其它区: "331182",
      松阳县: "331124",
      遂昌县: "331123",
      云和县: "331125"
    },
    宁波市: {
      北仑区: "330206",
      慈溪市: "330282",
      奉化区: "330283",
      海曙区: "330203",
      江北区: "330205",
      宁海县: "330226",
      其它区: "330284",
      象山县: "330225",
      鄞州区: "330212",
      余姚市: "330281",
      镇海区: "330211"
    },
    衢州市: {
      常山县: "330822",
      江山市: "330881",
      开化县: "330824",
      柯城区: "330802",
      龙游县: "330825",
      其它区: "330882",
      衢江区: "330803"
    },
    绍兴市: {
      柯桥区: "330621",
      其它区: "330684",
      上虞区: "330682",
      嵊州市: "330683",
      新昌县: "330624",
      越城区: "330602",
      诸暨市: "330681"
    },
    台州市: {
      黄岩区: "331003",
      椒江区: "331002",
      临海市: "331082",
      路桥区: "331004",
      其它区: "331083",
      三门县: "331022",
      天台县: "331023",
      温岭市: "331081",
      仙居县: "331024",
      玉环市: "331021"
    },
    温州市: {
      苍南县: "330327",
      洞头区: "330322",
      龙湾区: "330303",
      鹿城区: "330302",
      瓯海区: "330304",
      平阳县: "330326",
      其它区: "330383",
      瑞安市: "330381",
      泰顺县: "330329",
      文成县: "330328",
      永嘉县: "330324",
      乐清市: "330382"
    },
    舟山市: {
      岱山县: "330921",
      定海区: "330902",
      普陀区: "330903",
      其它区: "330923",
      嵊泗县: "330922"
    }
  },
  北京: {
    昌平区: "110114",
    朝阳区: "110105",
    大兴区: "110115",
    东城区: "110101",
    房山区: "110111",
    丰台区: "110106",
    海淀区: "110108",
    怀柔区: "110116",
    门头沟区: "110109",
    密云区: "110228",
    平谷区: "110117",
    其它区: "110230",
    石景山区: "110107",
    顺义区: "110113",
    通州区: "110112",
    西城区: "110102",
    延庆区: "110229"
  },
  上海: {
    宝山区: "310113",
    长宁区: "310105",
    崇明区: "310230",
    奉贤区: "310120",
    虹口区: "310109",
    黄浦区: "310101",
    嘉定区: "310114",
    静安区: "310106",
    金山区: "310116",
    闵行区: "310112",
    浦东新区: "310115",
    普陀区: "310107",
    青浦区: "310118",
    其它区: "310231",
    松江区: "310117",
    徐汇区: "310104",
    杨浦区: "310110"
  },
  重庆: {
    巴南区: "500113",
    北碚区: "500109",
    璧山区: "500227",
    长寿区: "500115",
    城口县: "500229",
    大渡口区: "500104",
    大足区: "500225",
    垫江县: "500231",
    丰都县: "500230",
    奉节县: "500236",
    涪陵区: "500102",
    合川区: "500382",
    江北区: "500105",
    江津区: "500381",
    九龙坡区: "500107",
    开州区: "500234",
    梁平区: "500228",
    南岸区: "500108",
    南川区: "500384",
    彭水苗族土家族自治县: "500243",
    黔江区: "500114",
    綦江区: "500222",
    其它区: "500385",
    荣昌区: "500226",
    沙坪坝区: "500106",
    石柱土家族自治县: "500240",
    铜梁区: "500224",
    潼南区: "500223",
    万州区: "500101",
    武隆区: "500232",
    巫山县: "500237",
    巫溪县: "500238",
    秀山土家族苗族自治县: "500241",
    永川区: "500383",
    酉阳土家族苗族自治县: "500242",
    渝北区: "500112",
    云阳县: "500235",
    渝中区: "500103",
    忠县: "500233"
  },
  天津: {
    宝坻区: "120115",
    北辰区: "120113",
    滨海新区: "120116",
    东丽区: "120110",
    河北区: "120105",
    河东区: "120102",
    和平区: "120101",
    河西区: "120103",
    红桥区: "120106",
    静海区: "120223",
    津南区: "120112",
    蓟州区: "120225",
    南开区: "120104",
    宁河区: "120221",
    其它区: "120226",
    武清区: "120114",
    西青区: "120111"
  }
};
// 匹配字符串相似度
function similar(s, t, f) {
  if (!s || !t) {
    return 0;
  }
  var l = s.length > t.length ? s.length : t.length;
  var n = s.length;
  var m = t.length;
  var d = [];
  f = f || 3;
  var min = function(a, b, c) {
    return a < b ? (a < c ? a : c) : b < c ? b : c;
  };
  var i, j, si, tj, cost;
  if (n === 0) return m;
  if (m === 0) return n;
  for (i = 0; i <= n; i++) {
    d[i] = [];
    d[i][0] = i;
  }
  for (j = 0; j <= m; j++) {
    d[0][j] = j;
  }
  for (i = 1; i <= n; i++) {
    si = s.charAt(i - 1);
    for (j = 1; j <= m; j++) {
      tj = t.charAt(j - 1);
      if (si === tj) {
        cost = 0;
      } else {
        cost = 1;
      }
      d[i][j] = min(d[i - 1][j] + 1, d[i][j - 1] + 1, d[i - 1][j - 1] + cost);
    }
  }
  let res = 1 - d[n][m] / l;
  return res.toFixed(f);
}

// 组装'province', 'city', 'county', 'address', 回  addressRsult
function setAddress(addressRsult, address) {
  address = address.replace(/\s/g, ""); // TODO 去除空格试试
  // 省
  let proviceArr = [];
  let provinceArr2 = [];
  // 市
  let cityArr = [];

  //区
  let countyArr = [];
  for (const province in addressList) {
    const provinceSplit = address.split(province);
    if (provinceSplit.length > 1) {
      // console.log("省份", province, provinceSplit);
      //多个省
      proviceArr.push(province);
      proviceArr.forEach(pro => {
        provinceArr2 = proviceArr
          .map(pro => {
            const index = address.indexOf(pro);
            return {
              full: pro + "省",
              pro,
              index
            };
          })
          .sort((a, b) => {
            return a.index - b.index;
          });
        if (provinceArr2[0]) {
          addressRsult.province = provinceArr2[0].full;
          // 市
          for (const city in addressList[pro]) {
            const cityReplace = city
              .replace("市", "")
              .replace("区", "")
              .replace("县", "");
            const citySplit = address.replace(pro, "").split(cityReplace);

            let cityArr2 = [];
            let countyArr2 = [];
            //匹配到了第二级
            if (citySplit.length > 1) {
              cityArr.push(cityReplace);
              cityArr2 = cityArr
                .map(shortCity => {
                  const index = address.indexOf(shortCity);
                  return {
                    full: city,
                    city: shortCity,
                    index
                  };
                })
                .sort((a, b) => {
                  return a.index - b.index;
                })
                .filter(item => item.index > 0);

              //城市的数组存在？
              if (cityArr2[0]) {
                addressRsult.city = cityArr2[0].full;
                // 记录当前省和城市
                let currentProvice = pro;
                let currentCity = cityArr2[0].city.concat("市");
                if (
                  typeof addressList[currentProvice][currentCity] == "object"
                ) {
                  //遍历区
                  for (const county in addressList[currentProvice][
                    currentCity
                  ]) {
                    const countyReplace = county
                      .replace(/'市'$/, "")
                      .replace("区", "")
                      .replace("县", "")
                      .replace("街道", "");

                    const countySplit = address
                      .replace(currentProvice, "")
                      .replace(currentCity, "")
                      .split(countyReplace);
                    // console.log("区", countyReplace, countySplit);
                    if (countySplit.length > 1) {
                      countyArr.push(countyReplace);
                      countyArr2 = countyArr
                        .map(curCounty => {
                          const index = address.indexOf(curCounty);
                          return {
                            full: county,
                            county: curCounty,
                            index
                          };
                        })
                        .sort((a, b) => {
                          return a.index - b.index;
                        })
                        .filter(item => item.index > 0);
                      // 区的数组存在;
                      if (countyArr2[0]) {
                        addressRsult.county = countyArr2[0].full;
                      }
                    }
                  }
                }

                addressRsult.address = address
                  .replace(addressRsult.province, "")
                  .replace(addressRsult.province, "")
                  .replace(addressRsult.city, "")
                  .replace(addressRsult.city, "")
                  .replace(addressRsult.county, "")
                  // .replace("省", "")
                  // .replace("市", "")
                  // .replace("市", "")
                  // .replace("区", "")
                  // .replace("县", "")
                  .replace(/=/g, "");
              }
            }
          }
        }
      });
    }
  }
}

export function setAddressRsultJs(address) {
  console.log("[address] ---> ", address);
  let mohuPro = [];
  let mohuArr = [];
  let mohuArr2 = [];
  let countyArr = [];
  let countyArr2 = [];
  const addressRsult = {
    province: "",
    city: "",
    county: "",
    address: ""
  };
  setAddress(addressRsult, address);
  // 直辖市单独处理
  if (
    addressRsult.province.indexOf("北京") === 0 ||
    addressRsult.province.indexOf("上海") === 0 ||
    addressRsult.province.indexOf("天津") === 0 ||
    addressRsult.province.indexOf("重庆") === 0
  ) {
    addressRsult.province = addressRsult.province.replace('省', '市')
    addressRsult.county = addressRsult.city;
    addressRsult.city = addressRsult.province;
    const temp = addressRsult.province.replace('市', '')
    addressRsult.address = addressRsult.address.replace(temp, '');
  } else {
    if (!addressRsult.province) {
      for (const province in addressList) {
        let res = similar(address, province);
        const obj = {
          res,
          province
        };
        mohuPro.push(obj);
        let mohuProvince = mohuPro.sort((a, b) => {
          return b.res - a.res;
        })[0].province;
        addressRsult.province = mohuProvince;
      }
    }
    if (!addressRsult.city) {
      let currentProvince = addressRsult.province.replace('省');
      for (const city in addressList[currentProvince]) {
        let res = similar(city, address.replace(currentProvince, ""), 6);

        const obj = {
          res,
          city: city
        };
        mohuArr.push(obj);
        let mohuCity = mohuArr.sort((a, b) => {
          return b.res - a.res;
        })[0].city;
        addressRsult.city = mohuCity;
        let currentCity = addressRsult.city;
        //区
        for (const county in addressList[currentProvince][currentCity]) {
          const countyReplace = county
            .replace("市", "")
            .replace("区", "")
            .replace("县", "")
            .replace("街道", "");
          const countySplit = address.split(countyReplace);
          // console.log("区", countyReplace, countySplit);
          if (countySplit.length > 1) {
            countyArr.push(countyReplace);
            countyArr2 = countyArr
              .map(county => {
                const index = address.indexOf(county);
                return {
                  county,
                  index
                };
              })
              .sort((a, b) => {
                return a.index - b.index;
              })
              .filter(item => item.index > 0);
            // 区的数组存在;
            if (countyArr2[0]) {
              addressRsult.county = countyArr2[0].county;
            }
          }
        }
      }
    }
    if (!addressRsult.county) {
      let currentProvice = addressRsult.province.replace('省', '');
      let currentCity = addressRsult.city
      for (const county in addressList[currentProvice][currentCity]) {
        let res = similar(county, address.replace(currentProvice, ""));
        const obj = {
          res,
          county: county
        };
        mohuArr2.push(obj);
        let mohuCounty = mohuArr2.sort((a, b) => {
          return b.res - a.res;
        })[0].county;
        addressRsult.county = mohuCounty.replace("区", "");
      }
    }
  }
  if (!addressRsult.address) {
    addressRsult.address = address.replace(/=/g, "");
  }
  return addressRsult;
}
